import React, { FC } from 'react';
import styles from './styles.module.scss';

interface IProps {
    amount: number;
    currency: string;
}

const PriceStatus: FC<IProps> = ({ amount, currency }) => {
    return (
        <span className={amount > 0 ? styles.green : amount !== 0 ? styles.red : ''}>
            {amount.toFixed(2)}
            {currency}
        </span>
    );
};

export default PriceStatus;
