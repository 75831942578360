import React, { FC, SVGAttributes } from 'react';

const Atm: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 17.3184H13V16.3184H14C14.55 16.3184 15 15.8684 15 15.3184V12.3184C15 11.7684 14.55 11.3184 14 11.3184H11V10.3184H15V8.31836H13V7.31836H11V8.31836H10C9.45 8.31836 9 8.76836 9 9.31836V12.3184C9 12.8684 9.45 13.3184 10 13.3184H13V14.3184H9V16.3184H11V17.3184ZM20 4.31836H4C2.89 4.31836 2.01 5.20836 2.01 6.31836L2 18.3184C2 19.4284 2.89 20.3184 4 20.3184H20C21.11 20.3184 22 19.4284 22 18.3184V6.31836C22 5.20836 21.11 4.31836 20 4.31836ZM20 18.3184H4V6.31836H20V18.3184Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Atm;
