import React, { FC, ReactNode, useMemo, useRef } from 'react';
import { Icon } from '@components/icons';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import useClickOutside from '@hooks/helpers/useClickOutside';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
    className?: string;
    width?: number;
}

const cx = classNames.bind(styles);

const Drawer: FC<IProps> = ({ isOpen, children, onClose, className, width }) => {
    const ref = useRef(null);
    const rootStyle = useMemo(
        () => cx([styles.wrapper, { isOpen }, className]),
        [isOpen, className]
    );

    useClickOutside({
        ref,
        callback: onClose
    });

    return (
        <div
            className={rootStyle}
            ref={ref}
            style={!!width ? { width, transform: `translateX(${width}px)` } : {}}>
            <button className={styles.close} onClick={onClose}>
                <Icon name="close" />
            </button>
            {children}
        </div>
    );
};

export default Drawer;
