import React, { useState } from 'react';
import DataInfo from '@components/data_info';
import FormField from '@components/form_field';
import { useForm } from '@hooks/helpers/useForm';
import Input from '@components/common/input';
import { useLocales } from '@hooks/helpers/useLocales';
import DatePicker from '@components/date_picker';
import Phone from '@components/phone';
import Address from '@components/address';
import styles from './styles.module.scss';

const PersonalSection = () => {
    const [isEditGeneral, setIsEditGeneral] = useState(false);
    const [isEditAddress, setIsEditAddress] = useState(false);
    const form = useForm({});
    const { labelLocale } = useLocales();

    return (
        <div className={styles.wrapper}>
            <DataInfo
                label="General information"
                showEdit
                isEdit={isEditGeneral}
                form={form}
                onChangeEdit={() => setIsEditGeneral((state) => !state)}>
                <FormField
                    name="firstName"
                    renderComponent={(props) => {
                        return <Input full {...props} label={labelLocale('first_name')} />;
                    }}
                />
                <FormField
                    name="lastName"
                    renderComponent={(props) => {
                        return <Input full {...props} label={labelLocale('last_name')} />;
                    }}
                />
                <FormField
                    name="email"
                    renderComponent={(props) => {
                        return <Input full {...props} label={labelLocale('email')} />;
                    }}
                />
                <FormField
                    name="birthDate"
                    renderComponent={(props) => {
                        return <DatePicker {...props} label={labelLocale('birth_date')} full />;
                    }}
                />
                <FormField
                    name="occupancy"
                    renderComponent={(props) => {
                        return <Input full {...props} label={labelLocale('occupation')} />;
                    }}
                />
                <FormField
                    name="id"
                    renderComponent={(props) => {
                        return <Input full {...props} label={labelLocale('user_id')} />;
                    }}
                />
                <FormField
                    name="createdAt"
                    renderComponent={(props) => {
                        return <DatePicker {...props} label={labelLocale('date_created')} full />;
                    }}
                />
                <FormField
                    name="isVerified"
                    renderComponent={(props) => {
                        return <Input full {...props} label={labelLocale('verification')} />;
                    }}
                />
                <FormField
                    name="status"
                    renderComponent={(props) => {
                        return <Input full {...props} label={labelLocale('status')} />;
                    }}
                />
                <Phone label={labelLocale('primary_phone')} isForm />
                <Phone label={labelLocale('phone_number')} isForm />
                <FormField
                    name="email"
                    renderComponent={(props) => {
                        return <Input full {...props} label={labelLocale('primary_email')} />;
                    }}
                />
                <FormField
                    name="email"
                    renderComponent={(props) => {
                        return <Input full {...props} label={labelLocale('email')} />;
                    }}
                />
            </DataInfo>
            <DataInfo
                label="Billing address"
                showEdit
                isEdit={isEditAddress}
                form={form}
                full
                onChangeEdit={() => setIsEditAddress((state) => !state)}>
                <Address isFull name="billing_address" />
            </DataInfo>
        </div>
    );
};

export default PersonalSection;
