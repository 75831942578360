import React, { FC, SVGAttributes } from 'react';

const Headset: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 14.3184V18.3184H17V14.3184H19ZM7 14.3184V18.3184H6C5.45 18.3184 5 17.8684 5 17.3184V14.3184H7ZM12 1.31836C7.03 1.31836 3 5.34836 3 10.3184V17.3184C3 18.9784 4.34 20.3184 6 20.3184H9V12.3184H5V10.3184C5 6.44836 8.13 3.31836 12 3.31836C15.87 3.31836 19 6.44836 19 10.3184V12.3184H15V20.3184H19V21.3184H12V23.3184H18C19.66 23.3184 21 21.9784 21 20.3184V10.3184C21 5.34836 16.97 1.31836 12 1.31836Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Headset;
