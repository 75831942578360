import React, { FC, SVGAttributes } from 'react';

const Amex: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 345.8 248.77"
            xmlSpace="preserve"
        >
            <path
                d="M313.74 243.52H32.04C14.34 243.51 0 229.16 0 211.47V31.99C0 14.3 14.34-.04 32.04-.04h281.7c17.69 0 32.04 14.34 32.04 32.04v179.42c.04 17.69-14.28 32.06-31.97 32.1h-.07"
                fill="#006fcf"
            />
            <defs>
                <path d="M0 57.68h345.54v141.49H0z" id="a" />
            </defs>
            <clipPath id="b">
                <use overflow="visible" xlinkHref="#a" />
            </clipPath>
            <g clipPath="url(#b)">
                <path
                    d="M303.21 118.08h25.94v-60.4h-27.68v9.65l-6.16-9.65h-24.13v12.2l-5.36-12.2h-39.94c-1.88 0-3.75.14-5.61.42a32.8 32.8 0 0 0-4.48 1.07c-1.13.35-2.24.77-3.31 1.27-1.24.57-2.44 1.24-3.58 1.99v-4.72H78.65L75.1 68.24l-3.61-10.53H41.23v12.33l-5.47-12.36H11.04L0 83.5v34.58h17.54l3.22-8.66h6.16l3.31 8.66h134.69v-8.78l6.74 8.78h37.21v-4.39c.96.61 1.95 1.16 2.98 1.64 1.01.47 2.05.87 3.11 1.21 1.29.41 2.6.74 3.93.98 2.08.38 4.2.56 6.31.56h22.28l3.22-8.66h6.16l3.31 8.66h37.34v-8.95l5.7 8.95zm42.33 77.41v-56.67H131.19l-5.47 7.73-5.36-7.73H58.67v60.35h61.41l5.36-7.85 5.53 7.85h38.44v-13.02H169c4.43.01 8.82-.76 12.97-2.29v15.31h26.8v-8.77l6.74 8.77h116.3c3.17.01 6.32-.52 9.31-1.59 1.54-.56 3.02-1.28 4.42-2.14"
                    fill="#f7f7f7"
                />
            </g>
            <defs>
                <path d="M68.16 148.64h277.63v40.71H68.16z" id="c" />
            </defs>
            <clipPath id="d">
                <use overflow="visible" xlinkHref="#c" />
            </clipPath>
            <g clipPath="url(#d)">
                <path
                    d="M332.05 181.21h-20.87v8.14h20.59c8.79 0 14.02-5.36 14.02-12.97 0-7.27-4.72-11.5-12.62-11.5h-9.42a3.717 3.717 0 0 1-3.95-3.47c-.01-.14-.01-.29 0-.43a3.824 3.824 0 0 1 3.55-4.07c.14-.01.27-.01.41-.01h17.67l3.61-8.26h-20.99c-8.78 0-14.01 5.36-14.01 12.67 0 7.5 4.94 11.79 12.67 11.79h9.36a3.78 3.78 0 0 1 4.02 3.52c.01.14.01.28 0 .42.13 2.15-1.5 3.99-3.65 4.12-.12.01-.25.01-.37 0m-38.74.01h-20.87v8.14h20.63c8.78 0 14.01-5.36 14.01-12.97 0-7.27-4.71-11.5-12.62-11.5h-9.42a3.712 3.712 0 0 1-3.94-3.46 3.23 3.23 0 0 1 0-.44 3.808 3.808 0 0 1 3.53-4.07c.14-.01.28-.01.42-.01h17.68l3.61-8.26H285.3c-8.78 0-14.01 5.36-14.01 12.67 0 7.5 4.94 11.79 12.68 11.79h9.36a3.764 3.764 0 0 1 4.04 3.47c.01.17.01.34 0 .51a3.881 3.881 0 0 1-3.62 4.12c-.13.01-.26.01-.39.01m-26.8-24.32v-8.26h-32.6v40.74h32.57v-8.2h-22.76v-8.2h22.28v-8.14h-22.28v-7.91l22.79-.03zm-54.55.07c3.48 0 5.36 2.26 5.36 5.06s-1.87 5-5.36 5h-11.49v-10.06h11.49zm-11.47 17.73h4.62l12.15 14.66h12.16l-13.55-15.01c6.93-1.28 11.05-6.27 11.05-12.79 0-7.62-5.36-12.87-14.08-12.87h-22.15v40.74h9.77l.03-14.73zm-24.88-12.39a4.79 4.79 0 0 1-5.17 5.01H158.8v-10h11.69c3.42-.01 5.17 2.2 5.17 4.99m-26.68-13.62v40.74h9.78v-13.41h12.27c8.9 0 14.42-5.81 14.42-13.67 0-8.08-5.36-13.61-14.08-13.61l-22.39-.05zm-14.35 40.74h12.21l-16.35-20.53 16.23-20.18h-11.86l-10.01 12.87-9.94-12.87H102.7l16.29 20.37-16.23 20.37h11.87l10-13.09 10 13.06zm-33.91-32.45v-8.26H68.16v40.74h32.56v-8.2h-22.8v-8.2h22.28v-8.14H77.92v-7.91l22.8-.03z"
                    fill="#006fcf"
                />
            </g>
            <path
                d="m289.66 83.85-.12-3.38 2.04 3.38 15.58 24.36h11.41V67.47h-9.54v23.59l.12 3.02-1.8-3.02-15.25-23.59h-11.98v40.74h9.53l.01-24.36zm-38.96-1.16 2.97-7.61 2.96 7.61 3.61 9.02h-13.09l3.55-9.02zm16.17 25.53h10.99l-17.39-40.74H247.6l-17.44 40.74h10.39l3.43-8.72h19.36l3.53 8.72zm-42.63 0h.53l4.01-8.66h-2.96c-6.69 0-10.65-4.18-10.65-11.4v-.58c0-6.43 3.54-11.28 10.53-11.28h10.56v-8.79h-11.38c-12.57 0-19.43 8.44-19.43 20.02v.7c0 12.09 7.05 19.94 18.79 19.99m-34.72 0h9.77V67.48h-9.77v40.74zm-20.53-32.4c3.5 0 5.36 2.27 5.36 5.06s-1.85 5.01-5.36 5.01h-11.45V75.82h11.45zm-11.45 17.75h4.59l12.16 14.65h12.16L172.89 93.2c6.92-1.28 11.04-6.28 11.04-12.79 0-7.62-5.36-12.87-14.07-12.87h-22.11v40.74h9.78V93.57zm-16.93-17.8V67.5h-32.56v40.74h32.56v-8.31h-22.79v-8.12h22.27v-8.14h-22.27v-7.91h22.79zm-81 4.65-.26-10.99 11.3 38.78h8.85l11.28-38.6-.24 10.72v27.88h9.48V67.47H82.81l-7.74 28.85-7.79-28.82H50.12v40.74h9.49V80.42zm-38.85 2.27 2.96-7.61 2.92 7.61 3.6 9.02H17.15l3.61-9.02zm16.08 25.52h11.02L30.47 67.47H17.6L.16 108.21h10.41L14 99.49h19.37l3.47 8.72z"
                fill="#006fcf"
            />
        </svg>
    );
};

export default Amex;
