export const CONTROLLED_PROPS = [
    'disabled',
    'onBlur',
    'onChange',
    'onFocus',
    'onMouseDown',
    'readOnly',
    'value'
];

export const defaultFormatChars = {
    9: /[0-9]/,
    a: /[A-Za-z]/,
    '*': /[A-Za-z0-9]/
};
