import React, { FC, ReactNode, useMemo } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import HeadItem from '@components/table/head_item';

export type TColumn = {
    label: string;
    key?: string;
    isSort?: boolean;
    render?: (val: any, row: any) => ReactNode | string;
    filterOptions?: {
        value: string;
        label: string;
    }[];
};

export type TRow = {
    [key: string]: string | ReactNode | { [key: string]: string | ReactNode };
};

export type TFilter = {
    [key: string]: string[];
};

export type TSort = {
    key: string;
    value: 'asc' | 'desc';
};

interface IProps {
    columns: TColumn[];
    rows: TRow[];
    onRowClick?: (data: TRow) => void;
    onSortChange?: (key: string, value: 'asc' | 'desc') => void;
    onFilterChange?: (key: TFilter) => void;
    sort?: TSort;
    filter?: TFilter;
}

const cx = classNames.bind(styles);

const Table: FC<IProps> = ({
    columns,
    rows,
    onRowClick,
    onSortChange,
    sort,
    filter,
    onFilterChange
}) => {
    const rowStyle = useMemo(() => cx([{ isOnclick: !!onRowClick }]), [onRowClick]);

    function getValueByPath(obj: any, path?: string) {
        if (path) {
            return path.split('.').reduce((acc, part) => acc && acc[part], obj);
        }
        return '';
    }

    const handleSortChange = (key?: string) => {
        if (onSortChange && key) {
            if (sort?.key === key) {
                if (sort?.value === 'asc') {
                    onSortChange(key, 'desc');
                } else {
                    onSortChange(key, 'asc');
                }
            } else {
                onSortChange(key, 'asc');
            }
        }
    };

    const handleFilterChange = (key: string, value: string) => {
        if (onFilterChange) {
            if (filter?.[key]) {
                if (filter[key].includes(value)) {
                    onFilterChange({
                        ...filter,
                        [key]: filter[key].filter((item) => item !== value)
                    });
                } else {
                    onFilterChange({
                        ...filter,
                        [key]: [...filter[key], value]
                    });
                }
            } else {
                onFilterChange({
                    ...filter,
                    [key]: [value]
                });
            }
        }
    };

    return (
        <div className={styles.wrapper}>
            <table className={styles.table}>
                <thead className={styles.header}>
                    <tr>
                        {columns.map((column, i) => {
                            return (
                                <HeadItem
                                    key={i}
                                    handleFilterChange={handleFilterChange}
                                    column={column}
                                    filter={filter}
                                    handleSortChange={handleSortChange}
                                    sort={sort}
                                />
                            );
                        })}
                    </tr>
                </thead>
                <tbody className={styles.body}>
                    {rows.map((row, i) => {
                        return (
                            <tr
                                key={i}
                                className={rowStyle}
                                onClick={() => onRowClick && onRowClick(row)}>
                                {columns.map((column, i) => {
                                    return (
                                        <td key={i}>
                                            <div>
                                                {column?.render
                                                    ? column.render(
                                                          getValueByPath(row, column?.key),
                                                          row
                                                      )
                                                    : getValueByPath(row, column?.key)}
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
