import React, { useState } from 'react';
import Table from '@components/table';
import styles from './styles.module.scss';
import { transactions } from '@_mock/index';
import Status from '@components/common/status';
import PriceStatus from '@components/common/price_status';
import Typography from '@components/common/typography';
import RangeDatePicker from '@components/range_date_picker';
import Button from '@components/common/button';
import Drawer from '@components/drawer';
import Input from '@components/common/input';
import Accordion from '@components/common/accordin';
import MultiSelect from '@components/common/multi_select';
import Range from '@components/range';

const columns = [
    { label: 'Date and Time', key: 'date', isSort: true },
    {
        label: 'Transaction ID',
        key: 'id'
    },
    { label: 'Transaction Type', key: 'type' },
    { label: 'Consumer Name', key: 'consumer.name' },
    { label: 'Consumer ID', key: 'consumer.id' },
    { label: 'Action', key: 'action' },
    {
        label: 'Status',
        key: 'status',
        render: (value: string) => <Status variant="success">{value}</Status>
    },
    { label: 'Currency', key: 'currency' },
    {
        label: 'Amount',
        key: 'amount',
        render: (value: number) => <PriceStatus amount={value} currency="$" />
    }
];

const transactionsType = [
    { label: 'E-transfer', value: 'E-transfer' },
    { label: 'Credit card', value: 'Credit card' }
];

const TransactionsLayout = () => {
    const [sort, setSort] = useState<{
        key: string;
        value: 'asc' | 'desc';
    }>({
        value: 'asc',
        key: 'date'
    });
    const [filter, setFilter] = useState<{ [key: string]: string[] }>({});
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [range, setRange] = useState([0, 1000]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.text_wrapper}>
                    <Typography variant="h4">Transactions</Typography>
                    <Typography>{transactions.length} records found</Typography>
                </div>
                <div className={styles.actions}>
                    <RangeDatePicker value={[new Date(), new Date()]} />
                    <Button size="medium" variant="outlined" leftIcon="download">
                        Download
                    </Button>
                    <Button
                        size="medium"
                        variant="primary"
                        leftIcon="filter_bars"
                        onClick={() => setIsDrawerOpen(true)}>
                        Filter
                    </Button>
                </div>
            </div>
            <Table
                columns={columns}
                rows={transactions}
                onRowClick={({ id }) => {
                    console.log(id);
                }}
                onSortChange={(key, value) => {
                    console.log({ key, value });
                    setSort({ key, value });
                }}
                onFilterChange={(value) => setFilter(value)}
                sort={sort}
                filter={filter}
            />
            <Drawer
                className={styles.drawer}
                isOpen={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}>
                <Input full leftIcon="search" placeholder="Search for transaction ID" />
                <Accordion label="Date and Time" isDefaultOpened>
                    <div className={styles.date_accordion}>
                        <Button variant="outlined" size="small">
                            Last week
                        </Button>
                        <Button variant="outlined" size="small">
                            Last month
                        </Button>
                        <Button variant="outlined" size="small">
                            Last year
                        </Button>
                    </div>
                    <RangeDatePicker full value={[new Date(), new Date()]} />
                </Accordion>
                <Accordion label="Transaction Type" isDefaultOpened>
                    <MultiSelect
                        full
                        values={[
                            'E-transfer',
                            'E-transfer',
                            'E-transfer',
                            'E-transfer',
                            'E-transfer',
                            'E-transfer'
                        ]}
                        onChange={() => {}}
                        items={transactionsType}
                    />
                </Accordion>
                <Accordion label="Action" isDefaultOpened>
                    <MultiSelect
                        full
                        values={['E-transfer']}
                        onChange={() => {}}
                        items={transactionsType}
                    />
                </Accordion>
                <Accordion label="Status" isDefaultOpened>
                    <MultiSelect
                        full
                        values={['E-transfer']}
                        onChange={() => {}}
                        items={transactionsType}
                    />
                </Accordion>
                <Accordion label="Amount" isDefaultOpened>
                    <Range
                        min={0}
                        max={1000}
                        step={1}
                        values={range}
                        onChange={(val) => {
                            setRange(val);
                            console.log(val);
                        }}
                    />
                </Accordion>
            </Drawer>
        </div>
    );
};

export default TransactionsLayout;
