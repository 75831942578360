import React, { FC, SVGAttributes } from 'react';

const SettingsFilled: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.43 13.8772C19.47 13.5351 19.5 13.193 19.5 12.8295C19.5 12.466 19.47 12.1239 19.43 11.7817L21.54 10.0177C21.73 9.8573 21.78 9.56864 21.66 9.33343L19.66 5.63424C19.54 5.39903 19.27 5.3135 19.05 5.39903L16.56 6.46816C16.04 6.04051 15.48 5.6877 14.87 5.42041L14.49 2.58722C14.46 2.33063 14.25 2.13818 14 2.13818H9.99996C9.74996 2.13818 9.53996 2.33063 9.50996 2.58722L9.12996 5.42041C8.51996 5.6877 7.95996 6.0512 7.43996 6.46816L4.94996 5.39903C4.71996 5.30281 4.45996 5.39903 4.33996 5.63424L2.33996 9.33343C2.20996 9.56864 2.26996 9.8573 2.45996 10.0177L4.56996 11.7817C4.52996 12.1239 4.49996 12.4767 4.49996 12.8295C4.49996 13.1823 4.52996 13.5351 4.56996 13.8772L2.45996 15.6413C2.26996 15.8017 2.21996 16.0903 2.33996 16.3255L4.33996 20.0247C4.45996 20.2599 4.72996 20.3455 4.94996 20.2599L7.43996 19.1908C7.95996 19.6185 8.51996 19.9713 9.12996 20.2386L9.50996 23.0717C9.53996 23.3283 9.74996 23.5208 9.99996 23.5208H14C14.25 23.5208 14.46 23.3283 14.49 23.0717L14.87 20.2386C15.48 19.9713 16.04 19.6078 16.56 19.1908L19.05 20.2599C19.28 20.3562 19.54 20.2599 19.66 20.0247L21.66 16.3255C21.78 16.0903 21.73 15.8017 21.54 15.6413L19.43 13.8772ZM12 16.5714C10.07 16.5714 8.49996 14.8929 8.49996 12.8295C8.49996 10.7661 10.07 9.08753 12 9.08753C13.93 9.08753 15.5 10.7661 15.5 12.8295C15.5 14.8929 13.93 16.5714 12 16.5714Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default SettingsFilled;
