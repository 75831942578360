export const transactions = [
    {
        id: '28nby93n8b03223',
        date: '2024-01-23 13:22:03',
        type: 'E-transfer',
        consumer: {
            id: '8930284834',
            name: 'Joe Smith'
        },
        action: 'Send money',
        status: 'Approved',
        currency: 'CAD',
        amount: -23.0
    },
    {
        id: '28nby93n8b03223',
        date: '2024-01-23 13:22:03',
        type: 'E-transfer',
        consumer: {
            id: '8930284834',
            name: 'Joe Smith'
        },
        action: 'Send money',
        status: 'Approved',
        currency: 'CAD',
        amount: 23.0
    },
    {
        id: '28nby93n8b03223',
        date: '2024-01-23 13:22:03',
        type: 'E-transfer',
        consumer: {
            id: '8930284834',
            name: 'Joe Smith'
        },
        action: 'Send money',
        status: 'Approved',
        currency: 'CAD',
        amount: -23.0
    },
    {
        id: '28nby93n8b03223',
        date: '2024-01-23 13:22:03',
        type: 'E-transfer',
        consumer: {
            id: '8930284834',
            name: 'Joe Smith'
        },
        action: 'Send money',
        status: 'Approved',
        currency: 'CAD',
        amount: 23.0
    },
    {
        id: '28nby93n8b03223',
        date: '2024-01-23 13:22:03',
        type: 'E-transfer',
        consumer: {
            id: '8930284834',
            name: 'Joe Smith'
        },
        action: 'Send money',
        status: 'Approved',
        currency: 'CAD',
        amount: -23.0
    },
    {
        id: '28nby93n8b03223',
        date: '2024-01-23 13:22:03',
        type: 'E-transfer',
        consumer: {
            id: '8930284834',
            name: 'Joe Smith'
        },
        action: 'Send money',
        status: 'Approved',
        currency: 'CAD',
        amount: 23.0
    },
    {
        id: '28nby93n8b03223',
        date: '2024-01-23 13:22:03',
        type: 'E-transfer',
        consumer: {
            id: '8930284834',
            name: 'Joe Smith'
        },
        action: 'Send money',
        status: 'Approved',
        currency: 'CAD',
        amount: -23.0
    },
    {
        id: '28nby93n8b03223',
        date: '2024-01-23 13:22:03',
        type: 'E-transfer',
        consumer: {
            id: '8930284834',
            name: 'Joe Smith'
        },
        action: 'Send money',
        status: 'Approved',
        currency: 'CAD',
        amount: 23.0
    },
    {
        id: '28nby93n8b03223',
        date: '2024-01-23 13:22:03',
        type: 'E-transfer',
        consumer: {
            id: '8930284834',
            name: 'Joe Smith'
        },
        action: 'Send money',
        status: 'Approved',
        currency: 'CAD',
        amount: -23.0
    },
    {
        id: '28nby93n8b03223',
        date: '2024-01-23 13:22:03',
        type: 'E-transfer',
        consumer: {
            id: '8930284834',
            name: 'Joe Smith'
        },
        action: 'Send money',
        status: 'Approved',
        currency: 'CAD',
        amount: 23.0
    },
    {
        id: '28nby93n8b03223',
        date: '2024-01-23 13:22:03',
        type: 'E-transfer',
        consumer: {
            id: '8930284834',
            name: 'Joe Smith'
        },
        action: 'Send money',
        status: 'Approved',
        currency: 'CAD',
        amount: -23.0
    },
    {
        id: '28nby93n8b03223',
        date: '2024-01-23 13:22:03',
        type: 'E-transfer',
        consumer: {
            id: '8930284834',
            name: 'Joe Smith'
        },
        action: 'Send money',
        status: 'Approved',
        currency: 'CAD',
        amount: 23.0
    },
    {
        id: '28nby93n8b03223',
        date: '2024-01-23 13:22:03',
        type: 'E-transfer',
        consumer: {
            id: '8930284834',
            name: 'Joe Smith'
        },
        action: 'Send money',
        status: 'Approved',
        currency: 'CAD',
        amount: -23.0
    },
    {
        id: '28nby93n8b03223',
        date: '2024-01-23 13:22:03',
        type: 'E-transfer',
        consumer: {
            id: '8930284834',
            name: 'Joe Smith'
        },
        action: 'Send money',
        status: 'Approved',
        currency: 'CAD',
        amount: 23.0
    },
    {
        id: '28nby93n8b03223',
        date: '2024-01-23 13:22:03',
        type: 'E-transfer',
        consumer: {
            id: '8930284834',
            name: 'Joe Smith'
        },
        action: 'Send money',
        status: 'Approved',
        currency: 'CAD',
        amount: -23.0
    },
    {
        id: '28nby93n8b03223',
        date: '2024-01-23 13:22:03',
        type: 'E-transfer',
        consumer: {
            id: '8930284834',
            name: 'Joe Smith'
        },
        action: 'Send money',
        status: 'Approved',
        currency: 'CAD',
        amount: 23.0
    }
];

export const consumers = [
    {
        firstName: 'John',
        lastName: 'Smith',
        email: 'john.smith@example.com',
        phoneNumber: '1345678901',
        phoneCode: '+44',
        id: '1a2b3c4d5e',
        isVerified: true,
        status: 'active',
        createdAt: '2023-02-20T12:45:00.000Z'
    },
    {
        firstName: 'Jane',
        lastName: 'Johnson',
        email: 'jane.johnson@mail.com',
        phoneNumber: '2345678901',
        phoneCode: '+1',
        id: '6f7g8h9i0j',
        isVerified: false,
        status: 'suspend',
        createdAt: '2022-12-15T09:30:00.000Z'
    },
    {
        firstName: 'Alice',
        lastName: 'Williams',
        email: 'alice.williams@test.org',
        phoneNumber: '3456789012',
        phoneCode: '+49',
        id: '2k3l4m5n6o',
        isVerified: true,
        status: 'blocked',
        createdAt: '2024-01-01T14:22:00.000Z'
    },
    {
        firstName: 'Bob',
        lastName: 'Brown',
        email: 'bob.brown@email.net',
        phoneNumber: '4567890123',
        phoneCode: '+33',
        id: '7p8q9r0s1t',
        isVerified: false,
        status: 'active',
        createdAt: '2023-05-12T18:10:00.000Z'
    },
    {
        firstName: 'Charlie',
        lastName: 'Jones',
        email: 'charlie.jones@sample.co',
        phoneNumber: '5678901234',
        phoneCode: '+81',
        id: '4u5v6w7x8y',
        isVerified: true,
        status: 'suspend',
        createdAt: '2023-08-25T21:15:00.000Z'
    },
    {
        firstName: 'Diana',
        lastName: 'Garcia',
        email: 'diana.garcia@example.com',
        phoneNumber: '6789012345',
        phoneCode: '+44',
        id: '9z0a1b2c3d',
        isVerified: true,
        status: 'blocked',
        createdAt: '2023-03-14T13:00:00.000Z'
    },
    {
        firstName: 'Eve',
        lastName: 'Miller',
        email: 'eve.miller@mail.com',
        phoneNumber: '7890123456',
        phoneCode: '+1',
        id: '3e4f5g6h7i',
        isVerified: false,
        status: 'active',
        createdAt: '2022-11-05T17:50:00.000Z'
    },
    {
        firstName: 'Frank',
        lastName: 'Davis',
        email: 'frank.davis@test.org',
        phoneNumber: '8901234567',
        phoneCode: '+49',
        id: '8j9k0l1m2n',
        isVerified: true,
        status: 'suspend',
        createdAt: '2023-07-07T11:30:00.000Z'
    },
    {
        firstName: 'Grace',
        lastName: 'Rodriguez',
        email: 'grace.rodriguez@email.net',
        phoneNumber: '9012345678',
        phoneCode: '+33',
        id: '4o5p6q7r8s',
        isVerified: true,
        status: 'blocked',
        createdAt: '2024-02-19T10:45:00.000Z'
    },
    {
        firstName: 'Henry',
        lastName: 'Martinez',
        email: 'henry.martinez@sample.co',
        phoneNumber: '1234567890',
        phoneCode: '+81',
        id: '9t0u1v2w3x',
        isVerified: false,
        status: 'active',
        createdAt: '2023-01-27T08:00:00.000Z'
    }
];

export const cards = [
    {
        number: '**** 2394',
        type: 'virtual',
        expiry_date: '08/25',
        card_limit: 3145,
        currency: '$',
        balance: 1783.42,
        status: 'active',
        createdAt: '2023-04-11T15:22:36.547Z'
    },
    {
        number: '**** 9823',
        type: 'physical',
        expiry_date: '03/27',
        card_limit: 2500,
        currency: '$',
        balance: 1432.89,
        status: 'suspend',
        createdAt: '2022-10-15T09:30:00.000Z'
    },
    {
        number: '**** 5623',
        type: 'virtual',
        expiry_date: '01/30',
        card_limit: 4900,
        currency: '$',
        balance: 3245.67,
        status: 'active',
        createdAt: '2024-01-01T14:22:00.000Z'
    },
    {
        number: '**** 1289',
        type: 'physical',
        expiry_date: '12/29',
        card_limit: 2000,
        currency: '$',
        balance: 1567.0,
        status: 'suspend',
        createdAt: '2023-05-12T18:10:00.000Z'
    },
    {
        number: '**** 3476',
        type: 'virtual',
        expiry_date: '07/26',
        card_limit: 4500,
        currency: '$',
        balance: 2999.99,
        status: 'active',
        createdAt: '2023-08-25T21:15:00.000Z'
    },
    {
        number: '**** 6734',
        type: 'physical',
        expiry_date: '11/28',
        card_limit: 1700,
        currency: '$',
        balance: 879.56,
        status: 'suspend',
        createdAt: '2023-03-14T13:00:00.000Z'
    },
    {
        number: '**** 3489',
        type: 'virtual',
        expiry_date: '06/24',
        card_limit: 3000,
        currency: '$',
        balance: 2034.22,
        status: 'active',
        createdAt: '2022-11-05T17:50:00.000Z'
    },
    {
        number: '**** 8923',
        type: 'physical',
        expiry_date: '02/31',
        card_limit: 3600,
        currency: '$',
        balance: 1456.78,
        status: 'active',
        createdAt: '2023-07-07T11:30:00.000Z'
    },
    {
        number: '**** 2910',
        type: 'virtual',
        expiry_date: '09/25',
        card_limit: 2600,
        currency: '$',
        balance: 1899.0,
        status: 'suspend',
        createdAt: '2024-02-19T10:45:00.000Z'
    },
    {
        number: '**** 3742',
        type: 'physical',
        expiry_date: '04/26',
        card_limit: 4100,
        currency: '$',
        balance: 3078.45,
        status: 'active',
        createdAt: '2023-01-27T08:00:00.000Z'
    }
];
