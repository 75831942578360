import React, { useState, useLayoutEffect, FC } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

interface IHookProps {
    rippleCount: number;
    cleanUpFunction: () => void;
}

interface IRippleProps {
    variant: 'dark' | 'red' | 'gold';
}

const cx = classNames.bind(styles);

const useDebouncedRippleCleanUp = ({ rippleCount, cleanUpFunction }: IHookProps) => {
    useLayoutEffect(() => {
        //@ts-ignore
        let bounce: ReturnType<typeof setTimeout> = null;
        if (rippleCount > 0) {
            clearTimeout(bounce);

            bounce = setTimeout(() => {
                cleanUpFunction();
                clearTimeout(bounce);
            }, 2000);
        }

        return () => clearTimeout(bounce);
    }, [rippleCount, cleanUpFunction]);
};

const Ripple: FC<IRippleProps> = ({ variant }) => {
    const [rippleArray, setRippleArray] = useState<{ x: number; y: number; size: number }[]>([]);

    useDebouncedRippleCleanUp({
        rippleCount: rippleArray.length,
        cleanUpFunction: () => setRippleArray([])
    });

    const addRipple = (event: any) => {
        const rippleContainer = event.currentTarget.getBoundingClientRect();
        const size =
            rippleContainer.width > rippleContainer.height
                ? rippleContainer.width
                : rippleContainer.height;
        const x = event.pageX - rippleContainer.x - size / 2;
        const y = event.pageY - rippleContainer.y - size / 2;
        const newRipple = {
            x,
            y,
            size
        };

        setRippleArray((state) => [...state, newRipple]);
    };

    return (
        <div className={styles.wrapper} onMouseDown={addRipple}>
            {rippleArray.map((ripple, index) => {
                return (
                    <span
                        className={cx([variant])}
                        key={index}
                        style={{
                            top: ripple.y,
                            left: ripple.x,
                            width: ripple.size,
                            height: ripple.size
                        }}
                    />
                );
            })}
        </div>
    );
};

export default Ripple;
