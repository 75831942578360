import React, { FC, SVGAttributes } from 'react';

const ArrowDown: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 4L11 16.17L5.41 10.58L4 12L12 20L20 12L18.59 10.59L13 16.17L13 4L11 4Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default ArrowDown;
