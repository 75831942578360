import { useTranslation } from 'react-i18next';

export const useLocales = () => {
    const { t } = useTranslation(['form', 'requests', 'submit']);

    const validationLocale = (string: string) => {
        return t(`validation.${string}`, { ns: 'form' });
    };

    const labelLocale = (string: string) => {
        return t(`labels.${string}`, { ns: 'form' });
    };

    const placeholderLocale = (string: string) => {
        return t(`placeholder.${string}`, { ns: 'form' });
    };

    const requestSuccessLocale = (string: string) => {
        return t(`success.${string}`, { ns: 'requests' });
    };

    const requestErrorLocale = (string: string) => {
        return t(`error.${string}`, { ns: 'requests' });
    };

    const submitLocale = (string: string) => {
        return t(string, { ns: 'submit' });
    };

    return {
        validationLocale,
        labelLocale,
        placeholderLocale,
        requestSuccessLocale,
        requestErrorLocale,
        submitLocale,
        t
    };
};
