import React, { FC, SVGAttributes } from 'react';

const MoneyIn: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#FDF1DC" />
            <path
                d="M19.0002 12.0001L19.0002 24.1701L13.4102 18.5801L12.0002 20.0001L20.0002 28.0001L28.0002 20.0001L26.5902 18.5901L21.0002 24.1701L21.0002 12.0001L19.0002 12.0001Z"
                fill="#282828"
            />
        </svg>
    );
};

export default MoneyIn;
