import React, { FC, SVGAttributes } from 'react';

const CheckOutline: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 2.31836C6.48 2.31836 2 6.79836 2 12.3184C2 17.8384 6.48 22.3184 12 22.3184C17.52 22.3184 22 17.8384 22 12.3184C22 6.79836 17.52 2.31836 12 2.31836ZM12 20.3184C7.59 20.3184 4 16.7284 4 12.3184C4 7.90836 7.59 4.31836 12 4.31836C16.41 4.31836 20 7.90836 20 12.3184C20 16.7284 16.41 20.3184 12 20.3184ZM16.59 7.89836L10 14.4884L7.41 11.9084L6 13.3184L10 17.3184L18 9.31836L16.59 7.89836Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default CheckOutline;
