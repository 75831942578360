import React, { FC, SVGAttributes } from 'react';

const Warning: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 6.80836L19.53 19.8184H4.47L12 6.80836ZM12 2.81836L1 21.8184H23L12 2.81836ZM13 16.8184H11V18.8184H13V16.8184ZM13 10.8184H11V14.8184H13V10.8184Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Warning;
