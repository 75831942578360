import React, { FC, SVGAttributes } from 'react';

const Link: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17 7.31836H13V9.31836H17C18.65 9.31836 20 10.6684 20 12.3184C20 13.9684 18.65 15.3184 17 15.3184H13V17.3184H17C19.76 17.3184 22 15.0784 22 12.3184C22 9.55836 19.76 7.31836 17 7.31836ZM11 15.3184H7C5.35 15.3184 4 13.9684 4 12.3184C4 10.6684 5.35 9.31836 7 9.31836H11V7.31836H7C4.24 7.31836 2 9.55836 2 12.3184C2 15.0784 4.24 17.3184 7 17.3184H11V15.3184ZM8 11.3184H16V13.3184H8V11.3184Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Link;
