import React, { FC, ReactNode, useMemo, useState } from 'react';
import { Icon } from '@components/icons';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

interface IProps {
    children: ReactNode;
    label: string;
    isDefaultOpened?: boolean;
}

const cx = classNames.bind(styles);

const Accordion: FC<IProps> = ({ label, children, isDefaultOpened = false }) => {
    const [isOpen, setIsOpen] = useState(isDefaultOpened);

    const contentStyles = useMemo(() => cx([styles.content, { isOpen }]), [isOpen]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.title} onClick={() => setIsOpen((state) => !state)}>
                <Icon className={isOpen ? styles.opened : ''} name="nav_down" />
                {label}
            </div>
            <div className={contentStyles}>{children}</div>
        </div>
    );
};

export default Accordion;
