import React, { FC, SVGAttributes } from 'react';

const ErrorFilled: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2.82959C6.48 2.82959 2 7.30959 2 12.8296C2 18.3496 6.48 22.8296 12 22.8296C17.52 22.8296 22 18.3496 22 12.8296C22 7.30959 17.52 2.82959 12 2.82959ZM11 17.8296V15.8296H13V17.8296H11ZM11 7.82959V13.8296H13V7.82959H11Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default ErrorFilled;
