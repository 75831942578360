import React from 'react';
import DataInfo from '@components/data_info';
import FormField from '@components/form_field';
import { useForm } from '@hooks/helpers/useForm';
import Input from '@components/common/input';
import { useLocales } from '@hooks/helpers/useLocales';
import styles from './styles.module.scss';

const KYCSection = () => {
    const form = useForm({});
    const { labelLocale } = useLocales();

    return (
        <div className={styles.wrapper}>
            <DataInfo label="KYC" form={form}>
                <FormField
                    name="firstName"
                    renderComponent={(props) => {
                        return <Input full {...props} label={labelLocale('first_name')} />;
                    }}
                />
                <FormField
                    name="lastName"
                    renderComponent={(props) => {
                        return <Input full {...props} label={labelLocale('last_name')} />;
                    }}
                />
                <FormField
                    name="id"
                    renderComponent={(props) => {
                        return <Input full {...props} label="ID" />;
                    }}
                />
                <FormField
                    name="kyc_id"
                    renderComponent={(props) => {
                        return <Input full {...props} label="KYC ID" />;
                    }}
                />
            </DataInfo>
        </div>
    );
};

export default KYCSection;
