import React, { FC, SVGAttributes } from 'react';

const OpenEye: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 6.81836C15.79 6.81836 19.17 8.94836 20.82 12.3184C19.17 15.6884 15.79 17.8184 12 17.8184C8.21 17.8184 4.83 15.6884 3.18 12.3184C4.83 8.94836 8.21 6.81836 12 6.81836ZM12 4.81836C7 4.81836 2.73 7.92836 1 12.3184C2.73 16.7084 7 19.8184 12 19.8184C17 19.8184 21.27 16.7084 23 12.3184C21.27 7.92836 17 4.81836 12 4.81836ZM12 9.81836C13.38 9.81836 14.5 10.9384 14.5 12.3184C14.5 13.6984 13.38 14.8184 12 14.8184C10.62 14.8184 9.5 13.6984 9.5 12.3184C9.5 10.9384 10.62 9.81836 12 9.81836ZM12 7.81836C9.52 7.81836 7.5 9.83836 7.5 12.3184C7.5 14.7984 9.52 16.8184 12 16.8184C14.48 16.8184 16.5 14.7984 16.5 12.3184C16.5 9.83836 14.48 7.81836 12 7.81836Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default OpenEye;
