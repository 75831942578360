import React, { FC, ReactNode } from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import { Icon } from '@components/icons';
import { useForm } from '@hooks/helpers/useForm';
import { FormProvider } from 'react-hook-form';
import classNames from 'classnames/bind';

interface IProps {
    label: string;
    children: ReactNode;
    showEdit?: boolean;
    isEdit?: boolean;
    full?: boolean;
    onChangeEdit?: () => void;
    form?: ReturnType<typeof useForm>;
}

const cx = classNames.bind(styles);

const DataInfo: FC<IProps> = ({ label, children, isEdit, full, showEdit, onChangeEdit, form }) => {
    return (
        <div className={cx(styles.wrapper, { full })}>
            <div className={styles.header}>
                <Typography variant="h5" weight="bold">
                    {label}
                </Typography>
                {showEdit && (
                    <Button
                        size="normal"
                        variant="outlined"
                        onClick={() => onChangeEdit && onChangeEdit()}>
                        {!isEdit && <Icon name="edit" />}
                        {isEdit ? 'Save' : 'Edit'}
                    </Button>
                )}
            </div>
            <div className={styles.content}>
                {form ? <FormProvider {...form}>{children}</FormProvider> : children}
            </div>
        </div>
    );
};

export default DataInfo;
