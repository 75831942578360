import React from 'react';
// @ts-ignore
import logoIcon from '@svg/header_logo.svg';
// import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
// import Button from '@components/common/button';
// import Typography from '@components/common/typography';
import { Link } from 'react-router-dom';
import { routes } from '@const/routes';

const Header = () => {
    // const { i18n } = useTranslation(undefined, { keyPrefix: 'header' });

    // const handleChangeLanguage = () => {
    //     const lang = i18n.language === 'en' ? 'fr' : 'en';
    //     i18n.changeLanguage(lang);
    //     localStorage.setItem('language', lang);
    // };

    return (
        <header className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.mobile_justify} />
                <Link to={routes.transactions}>
                    <img src={logoIcon} alt="logo" />
                </Link>
                {/*<div className={styles.right}>*/}
                {/*    <Button className={styles.language} onClick={handleChangeLanguage}>*/}
                {/*        <Typography*/}
                {/*            className={i18n.language === 'en' ? styles.isActive : ''}*/}
                {/*            variant="body2"*/}
                {/*        >*/}
                {/*            EN*/}
                {/*        </Typography>*/}
                {/*        |*/}
                {/*        <Typography*/}
                {/*            className={i18n.language === 'fr' ? styles.isActive : ''}*/}
                {/*            variant="body2"*/}
                {/*        >*/}
                {/*            FR*/}
                {/*        </Typography>*/}
                {/*    </Button>*/}
                {/*</div>*/}
            </div>
        </header>
    );
};

export default Header;
