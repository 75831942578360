import React, { FC, SVGAttributes } from 'react';

const ErrorOutlined: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 15.3184H13V17.3184H11V15.3184ZM11 7.31836H13V13.3184H11V7.31836ZM11.99 2.31836C6.47 2.31836 2 6.79836 2 12.3184C2 17.8384 6.47 22.3184 11.99 22.3184C17.52 22.3184 22 17.8384 22 12.3184C22 6.79836 17.52 2.31836 11.99 2.31836ZM12 20.3184C7.58 20.3184 4 16.7384 4 12.3184C4 7.89836 7.58 4.31836 12 4.31836C16.42 4.31836 20 7.89836 20 12.3184C20 16.7384 16.42 20.3184 12 20.3184Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default ErrorOutlined;
