import React, { FC, SVGAttributes } from 'react';

const MoreHorizontal: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 11.3506C4.9 11.3506 4 12.3128 4 13.4888C4 14.6649 4.9 15.6271 6 15.6271C7.1 15.6271 8 14.6649 8 13.4888C8 12.3128 7.1 11.3506 6 11.3506ZM18 11.3506C16.9 11.3506 16 12.3128 16 13.4888C16 14.6649 16.9 15.6271 18 15.6271C19.1 15.6271 20 14.6649 20 13.4888C20 12.3128 19.1 11.3506 18 11.3506ZM10 13.4888C10 12.3128 10.9 11.3506 12 11.3506C13.1 11.3506 14 12.3128 14 13.4888C14 14.6649 13.1 15.6271 12 15.6271C10.9 15.6271 10 14.6649 10 13.4888Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default MoreHorizontal;
