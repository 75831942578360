import React, { createContext, useEffect, useState } from 'react';

import { Icon } from '@components/icons';
import Button from '@components/common/button';
import { modals } from '@const/modals';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { Outlet } from 'react-router-dom';
import { GlobalContextProvider } from '../global_context';

type TOpenModal = {
    modalId: string;
    onSubmit?: (modalData: any) => void;
};

export type TModal = {
    confirmLabel?: string;
    cancelLabel?: string;
    isHeader?: boolean;
    isFooter?: boolean;
    Component: React.FC | null;
    variant: 'medium' | 'small' | 'custom' | 'large';
} & TOpenModal;

type TModalContext = {
    isOpen: boolean;
    onOpen: (value: TOpenModal) => void;
    rerender: number;
    repeatRequest: () => void;
    changeModalData: (value: any) => void;
    modalData: any;
    onClose: () => void;
};

const initialState: TModal = {
    modalId: '',
    onSubmit: () => {},
    confirmLabel: '',
    cancelLabel: '',
    Component: null,
    variant: 'custom'
};

export const ModalContext = createContext<TModalContext>({
    isOpen: false,
    onOpen: () => {},
    rerender: 0,
    repeatRequest: () => {},
    modalData: '',
    changeModalData: () => {},
    onClose: () => {}
});

const cx = classNames.bind(styles);

const ModalContextProvider = () => {
    const [modalState, setModalState] = useState<TModal>(initialState);
    const [isOpen, setIsOpen] = useState(false);
    const [modalData, setModalData] = useState<any>('');
    const [rerender, setRerender] = useState(0);

    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    }, [isOpen]);

    const onOpen = (value: TOpenModal) => {
        const modal = modals.find((modal) => modal.modalId === value.modalId);

        if (modal) {
            setModalState({
                ...modal,
                ...value
            });
            setIsOpen(true);
        }
    };

    const handleCloseModal = () => {
        requestAnimationFrame(() => {
            setModalState(initialState);
        });
        setIsOpen(false);
        setModalData(undefined);
    };

    const changeModalData = (value: any) => {
        setModalData(value);
    };

    return (
        <ModalContext.Provider
            value={{
                isOpen: !!modalState.modalId,
                onOpen,
                rerender,
                modalData,
                onClose: handleCloseModal,
                changeModalData,
                repeatRequest: () => setRerender((state) => state + 1)
            }}>
            <GlobalContextProvider>
                <Outlet />
                <div
                    onClick={handleCloseModal}
                    className={styles.overlay}
                    style={{ display: isOpen ? 'block' : 'none' }}
                />
                {isOpen && (
                    <div className={cx([styles.dialog, modalState.variant])}>
                        {modalState.isHeader && (
                            <div className={styles.header}>
                                <button onClick={handleCloseModal}>
                                    <Icon name="close" width={24} height={24} />
                                </button>
                            </div>
                        )}
                        <div className={styles.container}>
                            {modalState.Component && <modalState.Component />}
                            {modalState.isFooter && (
                                <div className={styles.footer}>
                                    {modalState.cancelLabel && (
                                        <Button variant="outlined" onClick={handleCloseModal}>
                                            {modalState.cancelLabel}
                                        </Button>
                                    )}
                                    {modalState.confirmLabel && (
                                        <Button
                                            variant="primary"
                                            onClick={() => {
                                                if (modalState.onSubmit) {
                                                    modalState.onSubmit(modalData);
                                                }
                                            }}>
                                            {modalState.confirmLabel}
                                        </Button>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </GlobalContextProvider>
        </ModalContext.Provider>
    );
};

export default ModalContextProvider;
