import React, { FC, SVGAttributes } from 'react';

const Sync: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 4.31836V1.31836L8 5.31836L12 9.31836V6.31836C15.31 6.31836 18 9.00836 18 12.3184C18 13.3284 17.75 14.2884 17.3 15.1184L18.76 16.5784C19.54 15.3484 20 13.8884 20 12.3184C20 7.89836 16.42 4.31836 12 4.31836ZM12 18.3184C8.69 18.3184 6 15.6284 6 12.3184C6 11.3084 6.25 10.3484 6.7 9.51836L5.24 8.05836C4.46 9.28836 4 10.7484 4 12.3184C4 16.7384 7.58 20.3184 12 20.3184V23.3184L16 19.3184L12 15.3184V18.3184Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Sync;
