import React, { FC, SVGAttributes } from 'react';

const ClosedEye: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_600_2701)">
                <path
                    d="M9.34203 19.1005L7.41103 18.5825L8.19803 15.6435C7.01999 15.209 5.92514 14.5758 4.96103 13.7715L2.80803 15.9255L1.39303 14.5105L3.54703 12.3575C2.3311 10.9012 1.51411 9.15423 1.17603 7.28747L3.14403 6.92847C3.90303 11.1305 7.57903 14.3185 12 14.3185C16.42 14.3185 20.097 11.1305 20.856 6.92847L22.824 7.28647C22.4864 9.15348 21.6697 10.9008 20.454 12.3575L22.607 14.5105L21.192 15.9255L19.039 13.7715C18.0749 14.5758 16.9801 15.209 15.802 15.6435L16.589 18.5835L14.658 19.1005L13.87 16.1605C12.6324 16.3725 11.3677 16.3725 10.13 16.1605L9.34203 19.1005Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_600_2701">
                    <rect
                        width={props.width || 24}
                        height={props.height || 24}
                        fill="white"
                        transform="translate(0 0.318359)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ClosedEye;
