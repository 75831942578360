import React, { FC, SVGAttributes } from 'react';

const Bitcoin: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.56 12.2292C17.15 11.5392 17.5 10.6392 17.5 9.65918C17.5 7.79918 16.23 6.22918 14.5 5.78918V3.65918H12.5V5.65918H10.5V3.65918H8.5V5.65918H5.5V7.65918H7.5V17.6592H5.5V19.6592H8.5V21.6592H10.5V19.6592H12.5V21.6592H14.5V19.6592C16.71 19.6592 18.5 17.8692 18.5 15.6592C18.5 14.2092 17.72 12.9292 16.56 12.2292ZM9.5 7.65918H13.5C14.6 7.65918 15.5 8.55918 15.5 9.65918C15.5 10.7592 14.6 11.6592 13.5 11.6592H9.5V7.65918ZM14.5 17.6592H9.5V13.6592H14.5C15.6 13.6592 16.5 14.5592 16.5 15.6592C16.5 16.7592 15.6 17.6592 14.5 17.6592Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Bitcoin;
