import React, { FC, SVGAttributes } from 'react';

const Message: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 4.31836H20V16.3184H5.17L4 17.4884V4.31836ZM4 2.31836C2.9 2.31836 2.01 3.21836 2.01 4.31836L2 22.3184L6 18.3184H20C21.1 18.3184 22 17.4184 22 16.3184V4.31836C22 3.21836 21.1 2.31836 20 2.31836H4ZM6 12.3184H18V14.3184H6V12.3184ZM6 9.31836H18V11.3184H6V9.31836ZM6 6.31836H18V8.31836H6V6.31836Z"
                fill="#282828"/>
        </svg>

    );
};

export default Message;
