import React, { FC, useState } from 'react';
import { Icon } from '@components/icons';

interface IProps {
    items: string[];
    onChange: (value: string) => void;
    value: string;
}

export const SimpleDropdown: FC<IProps> = ({ items, onChange, value }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen((state) => !state);
    };

    return (
        <div
            className={`dropdown_wrapper ${isOpen ? 'isOpen' : ''}`}
            onClick={toggleDropdown}
            onMouseLeave={() => setIsOpen(false)}>
            <div className="label">
                {value} <Icon name="select_arrow" width={18} height={18} />
            </div>
            <div className="dropdown">
                {items.map((item) => (
                    <button key={item} onClick={() => onChange(item)}>
                        {item}
                    </button>
                ))}
            </div>
        </div>
    );
};
