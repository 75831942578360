import React, { FC, SVGAttributes } from 'react';

const NavLeft: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.705 7.41L14.295 6L8.29504 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default NavLeft;
