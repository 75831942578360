import React, { FC, SVGAttributes } from 'react';

const Search: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.76 13.9292L20.49 19.6592L19 21.1492L13.27 15.4192C12.2 16.1892 10.91 16.6592 9.5 16.6592C5.91 16.6592 3 13.7492 3 10.1592C3 6.56918 5.91 3.65918 9.5 3.65918C13.09 3.65918 16 6.56918 16 10.1592C16 11.5692 15.53 12.8592 14.76 13.9292ZM9.5 5.65918C7.01 5.65918 5 7.66918 5 10.1592C5 12.6492 7.01 14.6592 9.5 14.6592C11.99 14.6592 14 12.6492 14 10.1592C14 7.66918 11.99 5.65918 9.5 5.65918Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Search;
