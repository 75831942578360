import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { toast } from 'react-toastify';
import { Libraries, useJsApiLoader } from '@react-google-maps/api';
import { TAddress } from '@types';
import { Icon } from '@components/icons';
import { prettifyGoogleAddress } from '@utils/index';
import Typography from '@components/common/typography';
import { defaultAddressData } from '@const/default_form_data';
import { useTranslation } from 'react-i18next';

interface IProps {
    onChange?: (value: TAddress) => void;
    label?: string;
    full?: boolean;
    placeholder?: string;
    className?: string;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
    value: TAddress;
}

const cx = classNames.bind(styles);

const libraries = ['places'];

const AddressAutocomplete: FC<IProps> = ({
    onChange,
    label = 'autocomplete_label',
    helperText = 'autocomplete_helper',
    placeholder,
    full,
    error,
    className,
    disabled,
    value
}) => {
    const { t } = useTranslation('components', {
        keyPrefix: 'address'
    });
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string, // Replace with your API key
        libraries: libraries as Libraries,
        language: 'en'
    });
    const [inputId] = useState(String(Math.random()));
    const inputRef = useRef(null);
    const autocompleteRef = useRef(null);
    const rootStyles = useMemo(
        () => cx([styles.root, className, { disabled, full, isLeftIcon: true, error }]),
        [full, error, disabled, className]
    );
    const labelStyles = useMemo(() => cx([styles.label, { isActive: !!value }]), [value]);

    useEffect(() => {
        if (isLoaded && !autocompleteRef.current) {
            //@ts-ignore
            autocompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current);

            //@ts-ignore
            autocompleteRef.current.addListener('place_changed', () => {
                //@ts-ignore
                const place = autocompleteRef.current.getPlace();
                const objectOfAddress = prettifyGoogleAddress(place);
                if (objectOfAddress) {
                    if (onChange) onChange(objectOfAddress);
                } else {
                    if (onChange) onChange(defaultAddressData);
                    toast.error('Please choose a different address');
                }
            });
        }
    }, [isLoaded, inputRef]);
    const defaultValue = useMemo(() => {
        if (value?.country && value?.zipCode && value?.address1 && value?.region && value?.city) {
            return `${value.country}, ${value.city}, ${value.address1}, ${value.zipCode}`;
        }
        return '';
    }, [value]);

    return (
        <div className={rootStyles}>
            {label && (
                <label htmlFor={inputId} className={labelStyles}>
                    {t(label)}
                </label>
            )}
            <div className={styles.input_wrapper}>
                <button disabled={disabled}>
                    <Icon width={48} height={48} name="search" className={styles.left_icon} />
                </button>
                <input
                    defaultValue={defaultValue}
                    readOnly={disabled}
                    ref={inputRef}
                    className={`${styles.input} ${disabled ? styles.disabled : ''}`}
                    placeholder={placeholder}
                    id={inputId}
                    type="text"
                />
                {error && (
                    <Icon
                        width={48}
                        height={48}
                        name="error_filled"
                        className={styles.right_icon}
                    />
                )}
            </div>
            {helperText && (
                <Typography variant="body3" className={styles.helper_text}>
                    {t(helperText)}
                </Typography>
            )}
        </div>
    );
};

export default AddressAutocomplete;
