import React, { FC, SVGAttributes } from 'react';

const Money: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.39 11.2184C10.12 10.6284 9.39003 10.0184 9.39003 9.06836C9.39003 7.97836 10.4 7.21836 12.09 7.21836C13.87 7.21836 14.53 8.06836 14.59 9.31836H16.8C16.73 7.59836 15.68 6.01836 13.59 5.50836V3.31836H10.59V5.47836C8.65003 5.89836 7.09003 7.15836 7.09003 9.08836C7.09003 11.3984 9.00003 12.5484 11.79 13.2184C14.29 13.8184 14.79 14.6984 14.79 15.6284C14.79 16.3184 14.3 17.4184 12.09 17.4184C10.03 17.4184 9.22003 16.4984 9.11003 15.3184H6.91003C7.03003 17.5084 8.67003 18.7384 10.59 19.1484V21.3184H13.59V19.1684C15.54 18.7984 17.09 17.6684 17.09 15.6184C17.09 12.7784 14.66 11.8084 12.39 11.2184Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Money;
