import React, { FC, useEffect, useRef, useState } from 'react';
import Picker from 'react-datepicker';
import '@components/date_picker/styles.scss';
import { getFirstLetter } from '@utils/index';
import { Icon } from '@components/icons';
import moment from 'moment/moment';
import { CustomInput } from '@components/date_picker/input';
import { useTranslation } from 'react-i18next';
import { SimpleDropdown } from '@components/date_picker/dropdown';
import { shortMonths } from '@const/dates';
import { getYears } from '@utils/date';

interface IProps {
    helperText?: string;
    label?: string;
    placeholder?: string;
    full?: boolean;
    value?: string[] | Date[];
}

const RangeDatePicker: FC<IProps> = ({ helperText, full, label, placeholder, value }) => {
    const { t } = useTranslation('components', {
        keyPrefix: 'range_date_picker'
    });
    const ref = useRef<any>();
    const [finalDate, setFinalDate] = useState<any>([null, null]);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [date, setDate] = useState<any>([null, null]);

    useEffect(() => {
        if (value && value?.length > 1) {
            setDate(value);
        }
    }, [value]);

    return (
        <div className={`wrapper ${full ? 'full' : ''}`}>
            <Picker
                ref={ref}
                onChange={(date) => {
                    const [start, end] = date;
                    setStartDate(start);
                    setEndDate(end);
                    setFinalDate(date);
                }}
                showMonthDropdown
                showYearDropdown
                shouldCloseOnSelect={false}
                //@ts-ignore
                endDate={endDate}
                //@ts-ignore
                startDate={startDate}
                selectsRange
                renderDayContents={(day) => {
                    return <div>{day}</div>;
                }}
                //@ts-ignore
                formatWeekDay={(day) => <div>{getFirstLetter(day)}</div>}
                useShortMonthInDropdown
                dateFormat="MM/DD/YYYY"
                placeholderText={t('placeholder')}
                customInput={
                    <CustomInput
                        realValue={`${date[0] ? moment(date[0]).format('MM/DD/YYYY') : ''}${!!date[0] && !!date[1] ? ' - ' : ''}${date[1] ? moment(date[1]).format('MM/DD/YYYY') : ''}`}
                        label={label}
                        placeholder={placeholder}
                        helperText={helperText}
                        full={full}
                    />
                }
                renderCustomHeader={({
                    decreaseMonth,
                    increaseMonth,
                    increaseYear,
                    decreaseYear,
                    changeMonth,
                    changeYear,
                    date,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                    prevYearButtonDisabled,
                    nextYearButtonDisabled
                }) => {
                    return (
                        <div className="header_wrapper">
                            <div className="date_wrapper">
                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                    <Icon name="nav_left" />
                                </button>
                                <SimpleDropdown
                                    value={shortMonths[moment(date).month()]}
                                    items={shortMonths}
                                    onChange={(value) => changeMonth(shortMonths.indexOf(value))}
                                />
                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                    <Icon name="nav_right" />
                                </button>
                            </div>
                            <div className="date_wrapper">
                                <button onClick={decreaseYear} disabled={prevYearButtonDisabled}>
                                    <Icon name="nav_left" />
                                </button>
                                <SimpleDropdown
                                    value={String(moment(date).year())}
                                    items={getYears(100, true).map((item) => String(item))}
                                    onChange={(value) => changeYear(Number(value))}
                                />
                                <button onClick={increaseYear} disabled={nextYearButtonDisabled}>
                                    <Icon name="nav_right" />
                                </button>
                            </div>
                        </div>
                    );
                }}>
                <div className="footer">
                    <button
                        onClick={() => {
                            ref.current.setOpen(false);
                        }}>
                        {t('cancel')}
                    </button>
                    <button
                        onClick={() => {
                            setDate(finalDate);
                            ref.current.setOpen(false);
                        }}>
                        {t('ok')}
                    </button>
                </div>
            </Picker>
        </div>
    );
};

export default RangeDatePicker;
