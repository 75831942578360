import React, { FC, SVGAttributes } from 'react';

const Upload: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18 15.3184V18.3184H6V15.3184H4V18.3184C4 19.4184 4.9 20.3184 6 20.3184H18C19.1 20.3184 20 19.4184 20 18.3184V15.3184H18ZM7 9.31836L8.41 10.7284L11 8.14836V16.3184H13V8.14836L15.59 10.7284L17 9.31836L12 4.31836L7 9.31836Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Upload;
