import React from 'react';
import headerLogo from '@svg/header_logo.svg';
import NavLink from '@components/common/navlink';
import { routes } from '@const/routes';
import { Icon } from '@components/icons';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';

const Sidebar = () => {
    return (
        <aside className={styles.wrapper}>
            <div className={styles.header}>
                <img src={headerLogo} alt="" />
                Admin Portal
            </div>
            <div className={styles.navigation}>
                <NavLink href={routes.transactions}>
                    <Icon name="list" />
                    <Typography>Transactions</Typography>
                </NavLink>
                <NavLink href={routes.consumers}>
                    <Icon name="consumer" />
                    <Typography>Consumers</Typography>
                </NavLink>
                <NavLink href={routes.logs}>
                    <Icon name="logs" />
                    <Typography>Logs</Typography>
                </NavLink>
                <NavLink href={routes.help_center}>
                    <Icon name="help" />
                    <Typography>Help Center</Typography>
                </NavLink>
            </div>
        </aside>
    );
};

export default Sidebar;
