import React, { useState } from 'react';
import DataInfo from '@components/data_info';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import { Icon } from '@components/icons';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import Select from '@components/common/select';
import Drawer from '@components/drawer';

const RequestsSection = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    return (
        <div className={styles.wrapper}>
            <DataInfo label="Requests" full>
                <div className={styles.content}>
                    <div className={styles.item}>
                        <div className={styles.title}>
                            <Icon name="new_message" />
                            Request to close account
                        </div>
                        <div className={styles.gray}>#00184</div>
                        <div className={styles.gray}>2024-01-04 16:25:33</div>
                        <Button onClick={() => setIsDrawerOpen(true)} variant="outlined">
                            View
                        </Button>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.title}>
                            <Icon name="message" />
                            Request to refund
                        </div>
                        <div className={styles.gray}>#00184</div>
                        <div className={styles.gray}>2024-01-04 16:25:33</div>
                        <Button onClick={() => setIsDrawerOpen(true)} variant="outlined">
                            View
                        </Button>
                    </div>
                </div>
            </DataInfo>
            <Drawer
                className={styles.drawer}
                isOpen={isDrawerOpen}
                width={670}
                onClose={() => setIsDrawerOpen(false)}>
                <Typography className={styles.title} variant="h5">
                    Request details
                </Typography>
                <div className={styles.drawer_content}>
                    <Input label="Request ID" full />
                    <Input label="Request Type" full />
                    <Select label="Request Status" full onChange={() => {}} items={[]} />
                    <Input label="Request Date " full />
                    <Input label="Description" full />
                </div>
            </Drawer>
        </div>
    );
};

export default RequestsSection;
