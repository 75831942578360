import React, { FC, SVGAttributes } from 'react';

const Visa: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 345.8 248.77"
            xmlSpace="preserve"
        >
            <path
                d="M313.63 242.76H32.27c-17.67-.01-32-14.33-32-32.01V31.51c0-17.67 14.32-32 32-32h281.35c17.67 0 32 14.32 32.01 32v179.2c.03 17.68-14.28 32.03-31.95 32.06-.02-.01-.04-.01-.05-.01"
                fill="#0066b2"
            />
            <path
                d="M158.3 159.21h-17.99l11.24-69.1h17.99zm-33.12-69.1-17.13 47.52-2.02-10.24-6.06-31.05s-.73-6.22-8.57-6.22H63.06l-.29 1.17a67.239 67.239 0 0 1 18.81 7.89l15.59 60.06h18.74l28.62-69.1-19.35-.03zm141.47 69.13h16.52l-14.4-69.13H254.3c-6.67 0-8.3 5.15-8.3 5.15l-26.82 63.98h18.75l3.75-10.27h22.86l2.11 10.27zm-19.79-24.44 9.46-25.88 5.35 25.85-14.81.03zm-26.28-28.03 2.57-14.84a51.933 51.933 0 0 0-16.18-3.01c-8.93 0-30.12 3.9-30.12 22.87 0 17.85 24.87 18.08 24.87 27.44s-22.32 7.7-29.67 1.79l-2.68 15.51s8.03 3.91 20.35 3.91 30.8-6.36 30.8-23.65c0-17.97-25.11-19.64-25.11-27.44s17.52-6.81 25.22-2.57"
                fill="#fff"
            />
            <path
                d="m106 127.41-6.06-31.05s-.73-6.22-8.57-6.22H63.06l-.29 1.16a69.6 69.6 0 0 1 26.7 13.4 54.005 54.005 0 0 1 16.58 22.72"
                fill="#f9a533"
            />
        </svg>
    );
};

export default Visa;
