import React, { FC, SVGAttributes } from 'react';

const Filter: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.00506 6.31836H17.0051L11.9951 12.6184L7.00506 6.31836ZM4.25506 5.92836C6.27506 8.51836 10.0051 13.3184 10.0051 13.3184V19.3184C10.0051 19.8684 10.4551 20.3184 11.0051 20.3184H13.0051C13.5551 20.3184 14.0051 19.8684 14.0051 19.3184V13.3184C14.0051 13.3184 17.7251 8.51836 19.7451 5.92836C20.2551 5.26836 19.7851 4.31836 18.9551 4.31836H5.04506C4.21506 4.31836 3.74506 5.26836 4.25506 5.92836Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Filter;
