import React, { FC } from 'react';
import FormField from '@components/form_field';
import { TAddress } from '@types';
import AddressAutocomplete from '@components/common/address';
import styles from './styles.module.scss';
import Input from '@components/common/input';
import { useLocales } from '@hooks/helpers/useLocales';

interface IProps {
    name: string;
    disabled?: boolean;
    isFull?: boolean;
    isShowAutocomplete?: boolean;
}

const FullAddress: FC<IProps> = ({ name, disabled, isFull = true, isShowAutocomplete }) => {
    const { labelLocale } = useLocales();

    return (
        <div className={styles.wrapper}>
            {isShowAutocomplete && (
                <FormField<TAddress>
                    name={name}
                    renderComponent={(props) => (
                        <AddressAutocomplete
                            {...props}
                            helperText={isFull ? undefined : ''}
                            label={isFull ? undefined : ''}
                            full
                            disabled={disabled}
                        />
                    )}
                />
            )}
            {isFull && (
                <div className={styles.address_wrapper}>
                    <FormField
                        name={`${name}.address1`}
                        renderComponent={(props) => (
                            <Input
                                {...props}
                                full
                                label={labelLocale('address_1')}
                                readOnly={disabled}
                            />
                        )}
                    />
                    <FormField
                        name={`${name}.address2`}
                        renderComponent={(props) => (
                            <Input
                                {...props}
                                full
                                label={labelLocale('address_2')}
                                readOnly={disabled}
                            />
                        )}
                    />
                    <div className={styles.row}>
                        <FormField
                            name={`${name}.country`}
                            renderComponent={(props) => (
                                <Input
                                    {...props}
                                    full
                                    label={labelLocale('country')}
                                    readOnly={disabled}
                                />
                            )}
                        />
                        <FormField
                            name={`${name}.city`}
                            renderComponent={(props) => (
                                <Input
                                    {...props}
                                    full
                                    label={labelLocale('city')}
                                    readOnly={disabled}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.row}>
                        <FormField
                            name={`${name}.region`}
                            renderComponent={(props) => (
                                <Input
                                    {...props}
                                    full
                                    label={labelLocale('region')}
                                    readOnly={disabled}
                                />
                            )}
                        />
                        <FormField
                            name={`${name}.zipCode`}
                            renderComponent={(props) => (
                                <Input
                                    {...props}
                                    full
                                    label={labelLocale('zipCode')}
                                    readOnly={disabled}
                                />
                            )}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default FullAddress;
