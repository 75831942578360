import React, { FC, SVGAttributes } from 'react';

const Share: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.97 17.4692C16.5 16.9692 17.21 16.6592 18 16.6592C19.66 16.6592 21 17.9992 21 19.6592C21 21.3192 19.66 22.6592 18 22.6592C16.34 22.6592 15 21.3192 15 19.6592C15 19.4192 15.04 19.1892 15.09 18.9692L8.04 14.8492C7.5 15.3492 6.79 15.6592 6 15.6592C4.34 15.6592 3 14.3192 3 12.6592C3 10.9992 4.34 9.65918 6 9.65918C6.79 9.65918 7.5 9.96918 8.04 10.4692L15.09 6.35918C15.04 6.13918 15 5.89918 15 5.65918C15 3.99918 16.34 2.65918 18 2.65918C19.66 2.65918 21 3.99918 21 5.65918C21 7.31918 19.66 8.65918 18 8.65918C17.21 8.65918 16.49 8.34918 15.96 7.84918L8.91 11.9592C8.96 12.1892 9 12.4192 9 12.6592C9 12.8992 8.96 13.1292 8.91 13.3592L15.97 17.4692ZM19 5.65918C19 5.10918 18.55 4.65918 18 4.65918C17.45 4.65918 17 5.10918 17 5.65918C17 6.20918 17.45 6.65918 18 6.65918C18.55 6.65918 19 6.20918 19 5.65918ZM6 13.6592C5.45 13.6592 5 13.2092 5 12.6592C5 12.1092 5.45 11.6592 6 11.6592C6.55 11.6592 7 12.1092 7 12.6592C7 13.2092 6.55 13.6592 6 13.6592ZM17 19.6592C17 20.2092 17.45 20.6592 18 20.6592C18.55 20.6592 19 20.2092 19 19.6592C19 19.1092 18.55 18.6592 18 18.6592C17.45 18.6592 17 19.1092 17 19.6592Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Share;
