import React, { createContext, FC, ReactNode, useState } from 'react';

type TNavigate = {
    label: string;
    route: string;
};

type TGlobalContext = {
    navigateButton: TNavigate;
    handleChangeBackLabel: (val: TNavigate) => void;
};

const initialState: TGlobalContext = {
    navigateButton: {
        label: '',
        route: ''
    },
    handleChangeBackLabel: () => {}
};

export const GlobalContext = createContext<TGlobalContext>(initialState);

interface IProps {
    children: ReactNode;
}

export const GlobalContextProvider: FC<IProps> = ({ children }) => {
    const [navigateButton, setNavigateButton] = useState({
        label: '',
        route: ''
    });

    const handleChangeBackLabel = (val: TNavigate) => {
        setNavigateButton(val);
    };

    return (
        <GlobalContext.Provider
            value={{
                navigateButton,
                handleChangeBackLabel
            }}>
            {children}
        </GlobalContext.Provider>
    );
};
