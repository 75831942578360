type ModalType = {
    modalId: string;
    confirmLabel?: string;
    cancelLabel?: string;
    isHeader?: boolean;
    isFooter?: boolean;
    variant: 'medium' | 'small' | 'custom' | 'large';
    Component: React.FC;
};

const modalIds = {
    LINK_BANK_OR_CARD: 'link xeppt_cards or bank'
};

// const settingsNotificationsModal: ModalType = {
//     modalId: modalIds.SETTINGS_NOTIFICATION,
//     confirmLabel: 'Done',
//     isHeader: true,
//     isFooter: true,
//     variant: 'small',
//     Component: NotificationsModal
// };

const modals: ModalType[] = [];

export { modals, modalIds };
