import React, { FC, SVGAttributes } from 'react';

const Task: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22 5.49836L10.59 16.9184L6.35 12.6784L7.76 11.2684L10.59 14.0984L20.59 4.09836L22 5.49836ZM19.79 10.5384C19.92 11.1084 20 11.7084 20 12.3184C20 16.7384 16.42 20.3184 12 20.3184C7.58 20.3184 4 16.7384 4 12.3184C4 7.89836 7.58 4.31836 12 4.31836C13.58 4.31836 15.04 4.77836 16.28 5.56836L17.72 4.12836C16.1 2.98836 14.13 2.31836 12 2.31836C6.48 2.31836 2 6.79836 2 12.3184C2 17.8384 6.48 22.3184 12 22.3184C17.52 22.3184 22 17.8384 22 12.3184C22 11.1284 21.78 9.98836 21.4 8.92836L19.79 10.5384Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Task;
