import React, { FC, SVGAttributes } from 'react';

const ArrowUp: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13 20L13 7.83L18.59 13.42L20 12L12 4L4 12L5.41 13.41L11 7.83L11 20L13 20Z"
                fill="#282828"
            />
        </svg>
    );
};

export default ArrowUp;
