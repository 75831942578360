import React, { FC, SVGAttributes } from 'react';

const Power: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13 3.31836H11V13.3184H13V3.31836ZM17.83 5.48836L16.41 6.90836C17.99 8.17836 19 10.1284 19 12.3184C19 16.1884 15.87 19.3184 12 19.3184C8.13 19.3184 5 16.1884 5 12.3184C5 10.1284 6.01 8.17836 7.58 6.89836L6.17 5.48836C4.23 7.13836 3 9.57836 3 12.3184C3 17.2884 7.03 21.3184 12 21.3184C16.97 21.3184 21 17.2884 21 12.3184C21 9.57836 19.77 7.13836 17.83 5.48836Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Power;
