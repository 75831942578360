import React, { FC, SVGAttributes } from 'react';

const NewMessage: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20 17.3184H4V5.31836H14.1C14.02 4.92836 13.92 4.20836 14.1 3.31836H4C2.9 3.31836 2 4.21836 2 5.31836V23.3184L6 19.3184H20C21.1 19.3184 22 18.4184 22 17.3184V8.29836C21.42 8.73836 20.74 9.06836 20 9.21836V17.3184Z"
                fill="#282828"
            />
            <path
                d="M19 7.31836C20.6569 7.31836 22 5.97521 22 4.31836C22 2.66151 20.6569 1.31836 19 1.31836C17.3431 1.31836 16 2.66151 16 4.31836C16 5.97521 17.3431 7.31836 19 7.31836Z"
                fill="#DC362E"
            />
            <path d="M14 13.3184H6V15.3184H14V13.3184Z" fill="#282828" />
            <path d="M18 10.3184H6V12.3184H18V10.3184Z" fill="#282828" />
            <path
                d="M6 9.31836H18V9.21836C16.79 8.96836 15.75 8.26836 15.03 7.31836H6V9.31836Z"
                fill="#282828"
            />
        </svg>
    );
};

export default NewMessage;
