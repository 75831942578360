import React, { FC, SVGAttributes } from 'react';

const Person: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 6.31836C13.1 6.31836 14 7.21836 14 8.31836C14 9.41836 13.1 10.3184 12 10.3184C10.9 10.3184 10 9.41836 10 8.31836C10 7.21836 10.9 6.31836 12 6.31836ZM12 16.3184C14.7 16.3184 17.8 17.6084 18 18.3184H6C6.23 17.5984 9.31 16.3184 12 16.3184ZM12 4.31836C9.79 4.31836 8 6.10836 8 8.31836C8 10.5284 9.79 12.3184 12 12.3184C14.21 12.3184 16 10.5284 16 8.31836C16 6.10836 14.21 4.31836 12 4.31836ZM12 14.3184C9.33 14.3184 4 15.6584 4 18.3184V20.3184H20V18.3184C20 15.6584 14.67 14.3184 12 14.3184Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Person;
