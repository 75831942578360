import React, { FC, SVGAttributes } from 'react';

const Clock: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.99 2.79736C6.47 2.79736 2 7.58707 2 13.4887C2 19.3903 6.47 24.18 11.99 24.18C17.52 24.18 22 19.3903 22 13.4887C22 7.58707 17.52 2.79736 11.99 2.79736ZM12 22.0417C7.58 22.0417 4 18.2142 4 13.4887C4 8.76311 7.58 4.93562 12 4.93562C16.42 4.93562 20 8.76311 20 13.4887C20 18.2142 16.42 22.0417 12 22.0417ZM11 14.6326L16.49 18.1501L17.51 16.3112L13 13.4138V7.52292H11V14.6326Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Clock;
