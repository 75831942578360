import React, { FC, useState } from 'react';
import styles from './styles.module.scss';
import Checkbox from '@components/common/checkbox';
import { TColumn, TFilter, TSort } from '@components/table/index';
import { Icon } from '@components/icons';
import classNames from 'classnames/bind';

interface IProps {
    column: TColumn;
    handleFilterChange: (key: string, value: string) => void;
    handleSortChange: (key: string) => void;
    sort?: TSort;
    filter?: TFilter;
}

const cx = classNames.bind(styles);

const HeadItem: FC<IProps> = ({ column, filter, sort, handleSortChange, handleFilterChange }) => {
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    return (
        <th className={cx([{ isSort: column.isSort }])}>
            <div>
                {column.label}{' '}
                {column.isSort && (
                    <button
                        className={styles.filter_button}
                        onClick={() => handleSortChange(column.key || '')}>
                        <Icon
                            className={cx([
                                {
                                    isDesc: sort?.key === column.key && sort?.value === 'desc'
                                }
                            ])}
                            name="nav_down"
                        />
                    </button>
                )}
                {column.filterOptions && (
                    <button
                        className={styles.filter_button}
                        onClick={() => setIsFilterOpen((state) => !state)}>
                        <Icon name="filter" />
                    </button>
                )}
                {column.filterOptions && isFilterOpen && (
                    <div className={styles.dropdown} onMouseLeave={() => setIsFilterOpen(false)}>
                        {column?.filterOptions?.map((option, i) => {
                            return (
                                <label className={styles.item} key={i}>
                                    <Checkbox
                                        onClick={() =>
                                            handleFilterChange(column.key || '', option.value)
                                        }
                                        checked={filter?.[column?.key || '']?.includes(
                                            option.value
                                        )}
                                        variant="gold"
                                    />
                                    <p>{option.label}</p>
                                </label>
                            );
                        })}
                    </div>
                )}
            </div>
        </th>
    );
};

export default HeadItem;
