import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import AuthProvider from './UI/layout_providers/auth_provider';
import SignInLayout from '@layouts/signin';
import ModalContextProvider from './context/modal_context';
import { routes } from '@const/routes';
import TransactionsLayout from '@layouts/transactions';
import GlobalProvider from './UI/layout_providers/global_provider';
import ConsumersLayout from '@layouts/consumers';
import LogsLayout from '@layouts/logs';
import HelpCenterLayout from '@layouts/help_center';
import ConsumerLayout from '@layouts/consumers/consumer';

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route>
            <Route element={<ModalContextProvider />}>
                <Route element={<AuthProvider />}>
                    <Route path={routes.signin} element={<SignInLayout />} />
                </Route>
                <Route element={<GlobalProvider />}>
                    <Route path={routes.transactions} element={<TransactionsLayout />} />
                    <Route path={routes.consumers} element={<ConsumersLayout />} />
                    <Route path={routes.consumer} element={<ConsumerLayout />} />
                    <Route path={routes.logs} element={<LogsLayout />} />
                    <Route path={routes.help_center} element={<HelpCenterLayout />} />
                </Route>
            </Route>
        </Route>
    )
);
