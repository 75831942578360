import { MutableRefObject, useEffect } from 'react';

interface IProps {
    callback: () => void;
    event?: string;
    ref: MutableRefObject<any> | MutableRefObject<any>[];
}

const useClickOutside = ({ ref, callback, event = 'mousedown' }: IProps) => {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (Array.isArray(ref)) {
                ref.forEach((childRef) => {
                    if (Array.isArray(childRef)) {
                        if (childRef[0].current && !childRef[0].current.contains(event.target)) {
                            callback();
                        }
                    } else {
                        if (ref[0].current && !ref[0].current.contains(event.target)) {
                            callback();
                        }
                    }
                });
            } else {
                if (ref.current && !ref.current.contains(event.target)) {
                    callback();
                }
            }
        }

        document.addEventListener(event, handleClickOutside);
        return () => {
            document.removeEventListener(event, handleClickOutside);
        };
    }, [ref, callback, event]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (Array.isArray(ref)) {
                ref.forEach((childRef) => {
                    if (Array.isArray(childRef)) {
                        if (childRef[0].current && !childRef[0].current.contains(event.target)) {
                            callback();
                        }
                    } else {
                        if (ref[0].current && !ref[0].current.contains(event.target)) {
                            callback();
                        }
                    }
                });
            } else {
                if (ref.current && !ref.current.contains(event.target)) {
                    callback();
                }
            }
        }

        document.addEventListener(event, handleClickOutside);
        return () => {
            document.removeEventListener(event, handleClickOutside);
        };
    }, [ref, callback, event]);

    return;
};

export default useClickOutside;
