import React, { FC, ReactNode, useRef, useState } from 'react';
import Input from '@components/common/input';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import Checkbox from '@components/common/checkbox';
import useClickOutside from '@hooks/helpers/useClickOutside';
import { Icon } from '@components/icons';

interface IProps {
    values: string[];
    onChange: (value: string[]) => void;
    label?: string;
    placeholder?: string;
    items: { label: string; value: string }[];
    full?: boolean;
    render?: () => ReactNode;
}

const cx = classNames.bind(styles);

const MultiSelect: FC<IProps> = ({ values, placeholder, full, label, items, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);

    const handleChange = (value: string) => {
        const newValues = values;

        if (newValues.includes(value)) {
            onChange(newValues.filter((item) => item !== value));
        } else {
            onChange([...values, value]);
        }
    };

    useClickOutside({
        ref,
        callback: () => setIsOpen(false),
        event: 'mousedown'
    });

    return (
        <div className={cx([styles.wrapper, { isFull: full }])} ref={ref}>
            <div className={styles.content}>
                {values
                    .map((value) => items.find((item) => item.value === value))
                    .map((item, i) => {
                        return (
                            <div key={i}>
                                <p>{item?.label}</p>
                                <button onClick={() => handleChange(item?.value || '')}>
                                    <Icon width={10} height={10} name="close" />
                                </button>
                            </div>
                        );
                    })}
            </div>
            <Input
                rightIcon="nav_down"
                placeholder={values.length === 0 ? placeholder : ''}
                label={label}
                full={full}
                className={cx([{ isOpen }])}
                readOnly
                onFocus={() => setIsOpen(true)}
                onClickIcon={() => setIsOpen((state) => !state)}
            />
            <div className={cx([styles.dropdown_wrapper, { isOpen }])}>
                <div className={styles.dropdown}>
                    {items.map((item, i) => (
                        <label key={i} onClick={() => handleChange(item.value)}>
                            <Checkbox variant="gold" checked={values.includes(item.value)} />{' '}
                            {item.label}
                        </label>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MultiSelect;
