import React, { FC, useMemo } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { Icon } from '@components/icons';
import Ripple from '@components/common/ripple';

interface IProps {
    checked?: boolean;
    isDelete?: boolean;
    onClick?: () => void;
    variant?: 'red' | 'dark' | 'gold';
    className?: string;
    disabled?: boolean;
}

const cx = classNames.bind(styles);
const Checkbox: FC<IProps> = ({
    disabled,
    checked,
    variant = 'dark',
    onClick,
    isDelete,
    className
}) => {
    const rootStyles = useMemo(
        () => cx([styles.root, variant, { isChecked: checked, isDisabled: disabled }, className]),
        [checked, variant, disabled, className]
    );

    return (
        <label className={rootStyles} onChange={onClick}>
            <span>{checked && <Icon name={isDelete ? 'minus' : 'check_checkbox'} />}</span>
            <input type="checkbox" disabled={disabled} />
            {!disabled && <Ripple variant={variant} />}
        </label>
    );
};

export default Checkbox;
