import React, { useState } from 'react';
import Table from '@components/table';
import styles from './styles.module.scss';
import { consumers, transactions } from '@_mock/index';
import Typography from '@components/common/typography';
import RangeDatePicker from '@components/range_date_picker';
import Button from '@components/common/button';
import Drawer from '@components/drawer';
import Input from '@components/common/input';
import Accordion from '@components/common/accordin';
import MultiSelect from '@components/common/multi_select';
import Range from '@components/range';
import { TUser } from '@types';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';

const columns = (navigate: ReturnType<typeof useNavigate>) => [
    {
        label: 'Name',
        key: 'name',
        render: (_: string, data: TUser) => `${data.firstName} ${data.lastName}`
    },
    {
        label: 'Email',
        key: 'email'
    },
    {
        label: 'Consumer ID',
        key: 'id'
    },
    {
        label: 'Phone',
        key: 'phone',
        render: (_: string, data: TUser) => `${data.phoneCode} ${data.phoneNumber}`
    },
    {
        label: 'Verification',
        key: 'isVerified',
        render: (value: boolean) => (value ? 'Verified' : 'Not verified'),
        filterOptions: [
            {
                label: 'Verified',
                value: 'verified'
            },
            {
                label: 'Not Verified',
                value: 'not_verified'
            }
        ]
    },
    {
        label: 'Status',
        key: 'status',
        filterOptions: [
            {
                label: 'Active',
                value: 'active'
            },
            {
                label: 'Blocked',
                value: 'blocked'
            },
            {
                label: 'Suspend',
                value: 'suspend'
            }
        ]
    },
    {
        label: 'Date created',
        key: 'createdAt',
        isSort: true,
        render: (value: string) => moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    {
        label: 'Profile',
        key: 'id',
        render: (value: string) => (
            <Button
                variant="outlined"
                style={{ background: 'none' }}
                onClick={() => navigate(`${routes.consumers}/${value}`)}>
                View Profile
            </Button>
        )
    }
];

const transactionsType = [
    { label: 'E-transfer', value: 'E-transfer' },
    { label: 'Credit card', value: 'Credit card' }
];

const ConsumersLayout = () => {
    const [sort, setSort] = useState<{
        key: string;
        value: 'asc' | 'desc';
    }>({
        value: 'asc',
        key: 'date'
    });
    const [filter, setFilter] = useState<{ [key: string]: string[] }>({});
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [range, setRange] = useState([0, 1000]);
    const navigate = useNavigate();

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.text_wrapper}>
                    <Typography variant="h4">Consumers</Typography>
                    <Typography>{transactions.length} records found</Typography>
                </div>
                <div className={styles.actions}>
                    <Input
                        className={styles.search}
                        leftIcon="search"
                        placeholder="Search for consumer name, email or ID"
                    />
                </div>
            </div>
            <Table
                columns={columns(navigate)}
                rows={consumers}
                onSortChange={(key, value) => {
                    console.log({ key, value });
                    setSort({ key, value });
                }}
                onFilterChange={(value) => setFilter(value)}
                sort={sort}
                filter={filter}
            />
            <Drawer
                className={styles.drawer}
                isOpen={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}>
                <Input full leftIcon="search" placeholder="Search for transaction ID" />
                <Accordion label="Date and Time" isDefaultOpened>
                    <div className={styles.date_accordion}>
                        <Button variant="outlined" size="small">
                            Last week
                        </Button>
                        <Button variant="outlined" size="small">
                            Last month
                        </Button>
                        <Button variant="outlined" size="small">
                            Last year
                        </Button>
                    </div>
                    <RangeDatePicker full value={[new Date(), new Date()]} />
                </Accordion>
                <Accordion label="Transaction Type" isDefaultOpened>
                    <MultiSelect
                        full
                        values={[
                            'E-transfer',
                            'E-transfer',
                            'E-transfer',
                            'E-transfer',
                            'E-transfer',
                            'E-transfer'
                        ]}
                        onChange={() => {}}
                        items={transactionsType}
                    />
                </Accordion>
                <Accordion label="Action" isDefaultOpened>
                    <MultiSelect
                        full
                        values={['E-transfer']}
                        onChange={() => {}}
                        items={transactionsType}
                    />
                </Accordion>
                <Accordion label="Status" isDefaultOpened>
                    <MultiSelect
                        full
                        values={['E-transfer']}
                        onChange={() => {}}
                        items={transactionsType}
                    />
                </Accordion>
                <Accordion label="Amount" isDefaultOpened>
                    <Range
                        min={0}
                        max={1000}
                        step={1}
                        values={range}
                        onChange={(val) => {
                            setRange(val);
                            console.log(val);
                        }}
                    />
                </Accordion>
            </Drawer>
        </div>
    );
};

export default ConsumersLayout;
