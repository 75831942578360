import React, { FC, SVGAttributes } from 'react';

const MoneyOut: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#F5CC82" />
            <path
                d="M21 28L21 15.83L26.59 21.42L28 20L20 12L12 20L13.41 21.41L19 15.83L19 28L21 28Z"
                fill="#282828"
            />
        </svg>
    );
};

export default MoneyOut;
