import React, { FC, SVGAttributes } from 'react';

const Cloud: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 6.31836C14.62 6.31836 16.88 8.17836 17.39 10.7484L17.69 12.2484L19.22 12.3584C20.78 12.4584 22 13.7684 22 15.3184C22 16.9684 20.65 18.3184 19 18.3184H6C3.79 18.3184 2 16.5284 2 14.3184C2 12.2684 3.53 10.5584 5.56 10.3484L6.63 10.2384L7.13 9.28836C8.08 7.45836 9.94 6.31836 12 6.31836ZM12 4.31836C9.11 4.31836 6.6 5.95836 5.35 8.35836C2.34 8.67836 0 11.2284 0 14.3184C0 17.6284 2.69 20.3184 6 20.3184H19C21.76 20.3184 24 18.0784 24 15.3184C24 12.6784 21.95 10.5384 19.35 10.3584C18.67 6.90836 15.64 4.31836 12 4.31836Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Cloud;
