import Input from '@components/common/input';
import React, { FC } from 'react';

interface IProps {
    helperText?: string;
    label?: string;
    placeholder?: string;
    onClick?: () => void;
    realValue: string;
    full: boolean;
    disabled?: boolean;
    error?: boolean;
}

export const CustomInput: FC<IProps & any> = (
    { full, onClick, error, realValue, placeholder, label, helperText, disabled },
    ref
) => {
    return (
        <Input
            ref={ref}
            rightIcon="calendar"
            label={label}
            value={realValue}
            onClick={onClick}
            readOnly
            helperText={helperText}
            inputClassName="calendar_input"
            stringClassName="input_wrapper"
            placeholder={placeholder}
            hideErrorIcon
            disabled={disabled}
            full={full}
            error={error}
        />
    );
};
