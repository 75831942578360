import React, { FC, SVGAttributes } from 'react';

const Mastercard: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 345.8 248.77"
            xmlSpace="preserve"
        >
            <path
                d="M313.69 243.52H32.03C14.34 243.51 0 229.17 0 211.47V32.03C0 14.34 14.34 0 32.03 0h281.66c17.69 0 32.03 14.34 32.04 32.03v179.39c.03 17.7-14.29 32.06-31.99 32.09-.02.01-.04.01-.05.01"
                fill="#36495d"
            />
            <path d="M143.81 69.54h58.09v104.38h-58.09z" fill="#f16522" />
            <path
                d="M147.5 121.73a66.238 66.238 0 0 1 25.36-52.19C144.04 46.88 102.3 51.87 79.64 80.7s-17.67 70.56 11.16 93.22c24.08 18.93 57.98 18.93 82.06 0a66.283 66.283 0 0 1-25.36-52.19"
                fill="#e41b24"
            />
            <path
                d="M280.26 121.72c.01 36.66-29.7 66.38-66.36 66.39-14.88 0-29.34-5-41.04-14.2 28.82-22.67 33.81-64.41 11.14-93.24-3.26-4.14-7-7.88-11.14-11.14 28.82-22.66 70.55-17.66 93.2 11.16 9.2 11.7 14.2 26.15 14.2 41.03m-6.33 41.14v-2.14h.86v-.43h-2.2v.43h.87v2.14h.47zm4.28 0v-2.57h-.66l-.81 1.76-.77-1.77h-.66v2.57h.47v-1.95l.72 1.68h.46l.73-1.68v1.95l.52.01z"
                fill="#f89e1c"
            />
        </svg>
    );
};

export default Mastercard;
