import { z } from 'zod';
import { validator } from '@xeppt/xeppt-sdk';

interface IProps {
    messages: {
        email: string;
    };
}

export const signInValidation = ({ messages }: IProps) =>
    z.object({
        email: validator.email(messages.email)
    });
