import React, { FC, SVGAttributes } from 'react';

const Lock: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18 8.81836H17V6.81836C17 4.05836 14.76 1.81836 12 1.81836C9.24 1.81836 7 4.05836 7 6.81836V8.81836H6C4.9 8.81836 4 9.71836 4 10.8184V20.8184C4 21.9184 4.9 22.8184 6 22.8184H18C19.1 22.8184 20 21.9184 20 20.8184V10.8184C20 9.71836 19.1 8.81836 18 8.81836ZM9 6.81836C9 5.15836 10.34 3.81836 12 3.81836C13.66 3.81836 15 5.15836 15 6.81836V8.81836H9V6.81836ZM18 20.8184H6V10.8184H18V20.8184ZM12 17.8184C13.1 17.8184 14 16.9184 14 15.8184C14 14.7184 13.1 13.8184 12 13.8184C10.9 13.8184 10 14.7184 10 15.8184C10 16.9184 10.9 17.8184 12 17.8184Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Lock;
