export const paymentSystems = {
    visa: {
        name: 'VISA'
        // Icon: <VisaIcon />,
    },
    mastercard: {
        name: 'MasterCard'
        // Icon: <VisaIcon />,
    }
};

export const supportedLanguages = ['en', 'fr'];
