import React, { FC, SVGAttributes } from 'react';

const Attach: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.5 6.65918V17.1592C15.5 19.3692 13.71 21.1592 11.5 21.1592C9.29 21.1592 7.5 19.3692 7.5 17.1592V6.65918C7.5 5.27918 8.62 4.15918 10 4.15918C11.38 4.15918 12.5 5.27918 12.5 6.65918V16.1592C12.5 16.7092 12.05 17.1592 11.5 17.1592C10.95 17.1592 10.5 16.7092 10.5 16.1592V6.65918H9V16.1592C9 17.5392 10.12 18.6592 11.5 18.6592C12.88 18.6592 14 17.5392 14 16.1592V6.65918C14 4.44918 12.21 2.65918 10 2.65918C7.79 2.65918 6 4.44918 6 6.65918V17.1592C6 20.1992 8.46 22.6592 11.5 22.6592C14.54 22.6592 17 20.1992 17 17.1592V6.65918H15.5Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Attach;
