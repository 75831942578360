import React, { useState } from 'react';
import Table from '@components/table';
import styles from './styles.module.scss';
import { transactions } from '@_mock/index';
import Status from '@components/common/status';
import Typography from '@components/common/typography';
import Drawer from '@components/drawer';
import Input from '@components/common/input';
import PriceStatus from '@components/common/price_status';
import Button from '@components/common/button';

const columns = (onDetails: (value: string) => void) => [
    { label: 'Date and Time', key: 'date', isSort: true },
    {
        label: 'Transaction ID',
        key: 'id'
    },
    { label: 'Transaction Type', key: 'type' },
    { label: 'Consumer Name', key: 'consumer.name' },
    { label: 'Consumer ID', key: 'consumer.id' },
    { label: 'Action', key: 'action' },
    {
        label: 'Status',
        key: 'status',
        render: (value: string) => <Status variant="success">{value}</Status>
    },
    { label: 'Currency', key: 'currency' },
    {
        label: 'Amount',
        key: 'amount',
        render: (value: number) => <PriceStatus amount={value} currency="$" />
    },
    {
        label: 'Details',
        key: 'id',
        render: (value: string) => (
            <Button
                onClick={() => onDetails(value)}
                variant="outlined"
                style={{ background: 'none' }}>
                Details
            </Button>
        )
    }
];

const TransactionsSection = () => {
    const [sort, setSort] = useState<{
        key: string;
        value: 'asc' | 'desc';
    }>({
        value: 'asc',
        key: 'date'
    });
    const [filter, setFilter] = useState<{ [key: string]: string[] }>({});
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.text_wrapper}>
                    <Typography variant="h4">Transactions</Typography>
                </div>
            </div>
            <Table
                columns={columns(() => setIsDrawerOpen(true))}
                rows={transactions}
                onRowClick={({ id }) => {
                    console.log(id);
                }}
                onSortChange={(key, value) => {
                    console.log({ key, value });
                    setSort({ key, value });
                }}
                onFilterChange={(value) => setFilter(value)}
                sort={sort}
                filter={filter}
            />
            <Drawer
                className={styles.drawer}
                isOpen={isDrawerOpen}
                width={670}
                onClose={() => setIsDrawerOpen(false)}>
                <Typography className={styles.title} variant="h5">
                    Transaction details
                </Typography>
                <div className={styles.content}>
                    <Input label="Transaction ID" full />
                    <Input label="Transaction Date" full />
                    <Input label="Transaction Type" full />
                    <Input label="Transaction Currency" full />
                    <Input label="Transaction Amount" full />
                    <Input label="Fee Amount" full />
                    <Input label="Settlement Status" full />
                    <Input label="Settlement Date" full />
                    <Input label="Terminal Type" full />
                    <Input label="Description" full />
                    <Input label="Merchant" full />
                    <Input label="Merchant City" full />
                    <Input label="Reference Number" full />
                </div>
            </Drawer>
        </div>
    );
};

export default TransactionsSection;
