import React, { FC, SVGAttributes } from 'react';

const Calendar: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19 4.65918H18V2.65918H16V4.65918H8V2.65918H6V4.65918H5C3.89 4.65918 3 5.55918 3 6.65918V20.6592C3 21.7592 3.89 22.6592 5 22.6592H19C20.1 22.6592 21 21.7592 21 20.6592V6.65918C21 5.55918 20.1 4.65918 19 4.65918ZM19 20.6592H5V9.65918H19V20.6592ZM6.5 13.6592C6.5 12.2792 7.62 11.1592 9 11.1592C10.38 11.1592 11.5 12.2792 11.5 13.6592C11.5 15.0392 10.38 16.1592 9 16.1592C7.62 16.1592 6.5 15.0392 6.5 13.6592Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Calendar;
