import React, { FC, SVGAttributes } from 'react';

const Point: FC<SVGAttributes<SVGElement>> = () => {
    return (
        <svg
            width="29"
            height="34"
            viewBox="0 0 29 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.9502 34L5.0507 24.0416C-0.416637 18.5418 -0.416638 9.62475 5.0507 4.12489C10.518 -1.37497 19.3823 -1.37497 24.8497 4.12489C30.317 9.62474 30.317 18.5418 24.8497 24.0416L14.9502 34Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Point;
