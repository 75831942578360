import React, {
    FC,
    InputHTMLAttributes,
    MutableRefObject,
    useEffect,
    useMemo,
    useState
} from 'react';
import { Icon, TIconType } from '@components/icons';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import Typography from '@components/common/typography';
import InputMask from '@components/common/input/masked_input';

interface IProps {
    type?: 'text' | 'password';
    value?: string;
    onChange?: (value: string) => void;
    leftIcon?: TIconType;
    rightIcon?: TIconType;
    helperText?: string;
    error?: boolean;
    label?: string;
    placeholder?: string;
    hideErrorIcon?: boolean;
    className?: string;
    mask?: string;
    unmask?: boolean;
    full?: boolean;
    onClickIcon?: () => void;
    disabled?: boolean;
    stringClassName?: string;
    ref?: MutableRefObject<null>;
    inputClassName?: string;
    wrapperRef?: MutableRefObject<null>;
}

const cx = classNames.bind(styles);

const Input: FC<IProps & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>> = ({
    type = 'text',
    value = '',
    onChange,
    placeholder,
    label,
    rightIcon,
    leftIcon,
    helperText,
    full,
    className,
    stringClassName,
    onClickIcon,
    error,
    disabled,
    ref,
    inputClassName,
    onBlur,
    unmask = true,
    hideErrorIcon,
    wrapperRef,
    ...props
}) => {
    const [isOpenEye, setIsOpenEye] = useState(false);
    const [inputId] = useState(String(props.id ? props.id : Math.random()));
    const [localValue, setLocalValue] = useState('');
    const rootStyles = useMemo(
        () =>
            cx([
                styles.root,
                className,
                {
                    disabled,
                    full,
                    isLeftIcon: !!leftIcon,
                    isRightIcon: !!rightIcon,
                    isOnclickIcon: onClickIcon,
                    error
                }
            ]),
        [full, leftIcon, rightIcon, onClickIcon, error, disabled, className]
    );

    const labelStyles = useMemo(() => cx([styles.label, { isActive: !!value }]), [value]);

    const handleClickIcon = () => {
        if (onClickIcon) {
            onClickIcon();
        }
    };

    useEffect(() => {
        if (value !== undefined) {
            setLocalValue(value);
        }
    }, [value]);

    const unmaskValue = (value: string) => {
        let unmaskedValue = '';
        if (props.mask) {
            for (let i = 0; i < value.length; i++) {
                const isValueChar =
                    props.mask[i] === '9' || props.mask[i] === 'a' || props.mask[i] === '*';
                //@ts-ignore
                const isMaskChar = value[i] === props.maskChar;

                if (isValueChar && !isMaskChar) unmaskedValue += value[i];
            }
        }

        return unmaskedValue;
    };

    return (
        <div className={`${rootStyles} ${stringClassName}`} onBlur={onBlur} ref={wrapperRef}>
            {label && (
                <label htmlFor={inputId} className={labelStyles}>
                    {label}
                </label>
            )}
            <div className={styles.input_wrapper}>
                {leftIcon && (
                    <button disabled={!onClickIcon} onClick={handleClickIcon}>
                        <Icon width={48} height={48} name={leftIcon} className={styles.left_icon} />
                    </button>
                )}
                <InputMask
                    disabled={disabled}
                    placeholder={placeholder}
                    type={type === 'password' ? (isOpenEye ? 'text' : 'password') : type}
                    value={localValue}
                    ref={ref}
                    id={inputId}
                    className={inputClassName}
                    onChange={(e: any) => {
                        if (props.mask && unmask) {
                            if (onChange) onChange(unmaskValue(e.target.value));
                            setLocalValue(unmaskValue(e.target.value));
                        } else {
                            if (onChange) onChange(e.target.value);
                            setLocalValue(e.target.value);
                        }
                    }}
                    {...props}
                />
                {rightIcon && (!error || hideErrorIcon) && (
                    <button disabled={!onClickIcon} onClick={handleClickIcon}>
                        <Icon
                            width={48}
                            height={48}
                            name={rightIcon}
                            className={styles.right_icon}
                        />
                    </button>
                )}
                {type === 'password' && (
                    <button
                        onClick={() => setIsOpenEye((state) => !state)}
                        style={{ cursor: 'pointer' }}>
                        <Icon
                            width={48}
                            height={48}
                            name={isOpenEye ? 'open_eye' : 'closed_eye'}
                            className={styles.right_icon}
                        />
                    </button>
                )}
                {error && type !== 'password' && !hideErrorIcon && (
                    <Icon
                        width={48}
                        height={48}
                        name="error_filled"
                        className={styles.right_icon}
                    />
                )}
            </div>
            {helperText && (
                <Typography variant="body3" className={styles.helper_text}>
                    {helperText}
                </Typography>
            )}
        </div>
    );
};

export default Input;
