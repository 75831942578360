import React, { FC, SVGAttributes } from 'react';

const Language: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 13.4887C2 7.58707 6.47 2.79736 11.99 2.79736C17.52 2.79736 22 7.58707 22 13.4887C22 19.3903 17.52 24.18 11.99 24.18C6.47 24.18 2 19.3903 2 13.4887ZM15.97 9.21214H18.92C17.96 7.44808 16.43 6.07959 14.59 5.40604C15.19 6.59277 15.65 7.87573 15.97 9.21214ZM12 4.97839C12.83 6.26134 13.48 7.68329 13.91 9.21214H10.09C10.52 7.68329 11.17 6.26134 12 4.97839ZM4 13.4887C4 14.2264 4.1 14.9427 4.26 15.6269H7.64C7.56 14.9213 7.5 14.2157 7.5 13.4887C7.5 12.7617 7.56 12.056 7.64 11.3504H4.26C4.1 12.0346 4 12.751 4 13.4887ZM5.08 17.7652H8.03C8.35 19.1016 8.81 20.3846 9.41 21.5713C7.57 20.8977 6.04 19.5399 5.08 17.7652ZM5.08 9.21214H8.03C8.35 7.87573 8.81 6.59277 9.41 5.40604C7.57 6.07959 6.04 7.43739 5.08 9.21214ZM12 21.9989C11.17 20.716 10.52 19.294 10.09 17.7652H13.91C13.48 19.294 12.83 20.716 12 21.9989ZM9.5 13.4887C9.5 14.2157 9.57 14.9213 9.66 15.6269H14.34C14.43 14.9213 14.5 14.2157 14.5 13.4887C14.5 12.7617 14.43 12.0453 14.34 11.3504H9.66C9.57 12.0453 9.5 12.7617 9.5 13.4887ZM14.59 21.5713C15.19 20.3846 15.65 19.1016 15.97 17.7652H18.92C17.96 19.5292 16.43 20.8977 14.59 21.5713ZM16.5 13.4887C16.5 14.2157 16.44 14.9213 16.36 15.6269H19.74C19.9 14.9427 20 14.2264 20 13.4887C20 12.751 19.9 12.0346 19.74 11.3504H16.36C16.44 12.056 16.5 12.7617 16.5 13.4887Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Language;
