import React, { FC } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

interface IProps {
    onChange: (val: string) => void;
    tabs: {
        label: string;
        value: string;
    }[];
    activeTab: string;
}

const cx = classNames.bind(styles);

const Tabs: FC<IProps> = ({ onChange, tabs, activeTab }) => {
    return (
        <div className={styles.wrapper}>
            {tabs.map((tab, i) => {
                return (
                    <div
                        className={cx([styles.tab, { isActive: tab.value === activeTab }])}
                        key={i}
                        onClick={() => onChange(tab.value)}>
                        <p>{tab.label}</p>
                    </div>
                );
            })}
        </div>
    );
};

export default Tabs;
