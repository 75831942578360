import React, { FC, SVGAttributes } from 'react';

const Pin: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 15.3184H12.5V9.31836H11V15.3184ZM18.75 9.31836V12.8184L16.25 9.31836H15V15.3184H16.25V11.8184L18.8 15.3184H20V9.31836H18.75ZM7.5 9.31836H4V15.3184H5.5V13.3184H7.5C8.35 13.3184 9 12.6684 9 11.8184V10.8184C9 9.96836 8.35 9.31836 7.5 9.31836ZM7.5 11.8184H5.5V10.8184H7.5V11.8184Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Pin;
