import React, { FC, SVGAttributes } from 'react';

const Send: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.51 6.34836L11.02 9.56836L3.5 8.56836L3.51 6.34836ZM11.01 15.0684L3.5 18.2884V16.0684L11.01 15.0684ZM1.51 3.31836L1.5 10.3184L16.5 12.3184L1.5 14.3184L1.51 21.3184L22.5 12.3184L1.51 3.31836Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Send;
