import React, { FC, SVGAttributes } from 'react';

const Delete: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16 9.31836V19.3184H8V9.31836H16ZM14.5 3.31836H9.5L8.5 4.31836H5V6.31836H19V4.31836H15.5L14.5 3.31836ZM18 7.31836H6V19.3184C6 20.4184 6.9 21.3184 8 21.3184H16C17.1 21.3184 18 20.4184 18 19.3184V7.31836Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Delete;
