import { Country } from 'country-state-city';
import { EAddressType } from '@xeppt/xeppt-sdk/types/user';

export const getFirstLetter = (string?: string) => {
    if (!string) return '';
    return string.charAt(0);
};

export const hideCardNumber = (
    value: number | string,
    type?: 'full' | 'partial',
    variant?: 'dots' | 'stars'
) => {
    const separator = variant === 'dots' ? '••••' : '****';
    const string = String(value);
    if (type === 'full') {
        return `${separator} ${string.slice(12)}`;
    } else {
        return `${string.slice(0, 4)} ${separator} ${separator} ${string.slice(12)}`;
    }
};

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const hidePhoneNumber = (value: string | number) => {
    const str = String(value);
    return (
        str
            .substring(0, str.length - 4)
            .split('')
            .map(() => '•')
            .join('') + str.substring(str.length - 4, str.length)
    );
};

export const joiningArrayWords = (array: string[]) => {
    if (array.length === 0) {
        return '';
    } else if (array.length === 1) {
        return array[0];
    } else {
        const joinedFormats = array.slice(0, -1).join(', ');
        return `${joinedFormats} or ${array[array.length - 1]}`;
    }
};

export const getBirthDisabledDates = (): Date => {
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 18);

    return maxDate;
};

export const prettifyGoogleAddress = ({ address_components }: any) => {
    let streetName = '';
    let streetNumber = '';
    const address = {
        country: '',
        city: '',
        address1: '',
        address2: '',
        zipCode: '',
        region: ''
    };

    for (const component of address_components) {
        const componentType = component.types[0];
        switch (componentType) {
            case 'street_number': {
                streetNumber = component.long_name;
                break;
            }
            case 'route': {
                streetName = component.long_name;
                break;
            }
            case 'locality': {
                address['city'] = component.long_name;
                break;
            }
            case 'country': {
                address['country'] = component.short_name;
                break;
            }
            case 'postal_code': {
                address['zipCode'] = component.long_name;
                break;
            }
            case 'administrative_area_level_1': {
                address['region'] += component.long_name;
                break;
            }
            default:
                break;
        }
    }
    if (
        address.country === '' ||
        address.city === '' ||
        streetName === '' ||
        streetNumber === '' ||
        address.zipCode === '' ||
        address.region === ''
    ) {
        return undefined;
    } else {
        return {
            ...address,
            address1: streetName + ' ' + streetNumber
        };
    }
};

export const getPhoneCodeFromIso = (isoCode: string) => {
    return Country.getCountryByCode(isoCode)?.phonecode || '1';
};

export const getAddressFormName = (type: EAddressType) => {
    if (type === EAddressType.BUSINESS) {
        return 'businessAddress';
    } else if (type === EAddressType.REGISTRATION) {
        return 'registrationAddress';
    } else {
        return 'tradingAddress';
    }
};
export const getAddressName = (type: EAddressType, localization: (val: string) => string) => {
    if (type === EAddressType.BUSINESS) {
        return localization('business');
    } else if (type === EAddressType.REGISTRATION) {
        return localization('registration');
    } else {
        return localization('trading');
    }
};
