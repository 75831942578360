import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const ContextController = (props: any) => {
    const { control } = useFormContext();

    return <Controller control={control} {...props} />;
};

export default ContextController;
