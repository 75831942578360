import React, { useState } from 'react';
import Table from '@components/table';
import styles from './styles.module.scss';
import { cards } from '@_mock/index';
import Status from '@components/common/status';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import Drawer from '@components/drawer';
import Input from '@components/common/input';
import Select from '@components/common/select';

const columns = (onView: (value: string) => void, onDetails: (value: string) => void) => [
    { label: 'Card number', key: 'number' },
    { label: 'Card type', key: 'type' },
    { label: 'Expiry date', key: 'expiry_date' },
    { label: 'Card limit', key: 'card_limit' },
    { label: 'Currency', key: 'currency' },
    { label: 'Balance', key: 'balance' },
    {
        label: 'Status',
        key: 'status',
        render: (value: string) => <Status variant="success">{value}</Status>
    },
    { label: 'Date created', key: 'createdAt', isSort: true },
    {
        label: 'Transactions',
        key: 'id',
        render: (value: string) => (
            <div className={styles.actions_wrapper}>
                <Button
                    onClick={() => onView(value)}
                    style={{ background: 'none' }}
                    variant="outlined">
                    View
                </Button>
                <Button onClick={() => onDetails(value)} variant="primary">
                    Card Details
                </Button>
            </div>
        )
    }
];

const CardsSection = () => {
    const [sort, setSort] = useState<{
        key: string;
        value: 'asc' | 'desc';
    }>({
        value: 'asc',
        key: 'date'
    });
    const [filter, setFilter] = useState<{ [key: string]: string[] }>({});
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleView = (val: string) => {
        console.log(val);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.text_wrapper}>
                    <Typography variant="h4">Cards</Typography>
                </div>
            </div>
            <Table
                columns={columns(handleView, () => setIsDrawerOpen(true))}
                rows={cards}
                onRowClick={({ id }) => {
                    console.log(id);
                }}
                onSortChange={(key, value) => {
                    console.log({ key, value });
                    setSort({ key, value });
                }}
                onFilterChange={(value) => setFilter(value)}
                sort={sort}
                filter={filter}
            />
            <Drawer
                className={styles.drawer}
                isOpen={isDrawerOpen}
                width={670}
                onClose={() => setIsDrawerOpen(false)}>
                <Typography className={styles.title} variant="h5">
                    Card details
                </Typography>
                <div className={styles.content}>
                    <Input label="Card number" full />
                    <Select label="Status" full onChange={() => {}} items={[]} />
                    <Input label="Card Type" full />
                    <Input label="Currency" full />
                    <Input label="Start Date" full />
                    <Input label="Expiry Date" full />
                    <Input label="Usage Limit" full />
                    <Input label="Available Limit" full />
                    <Input label="Balance" full />
                    <Input label="Reference" full />
                </div>
            </Drawer>
        </div>
    );
};

export default CardsSection;
