import React, { useLayoutEffect, forwardRef, ReactNode } from 'react';

import { useInputState, useInputElement, usePrevious } from './hooks';
import { validateMaxLength, validateChildren, validateMaskPlaceholder } from './validate-props';

import { defer } from './utils/defer';
import { isInputFocused } from './utils/input';
import { isFunction, toString, getElementDocument } from './utils/helpers';
import MaskUtils from './utils/mask';

interface IProps {
    alwaysShowMask?: boolean;
    beforeMaskedStateChange?: () => void;
    children?: ReactNode;
    mask?: any;
    maskPlaceholder?: string;
    onFocus?: () => void;
    onBlur?: () => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onMouseDown?: () => void;
}

const InputMask = forwardRef(function InputMask(
    {
        alwaysShowMask = false,
        children,
        mask,
        maskPlaceholder = '',
        beforeMaskedStateChange,
        ...restProps
    }: IProps & any,
    forwardedRef
) {
    const props = {
        alwaysShowMask,
        children,
        mask,
        maskPlaceholder,
        beforeMaskedStateChange,
        ...restProps
    };
    validateMaxLength(props);
    validateMaskPlaceholder(props);

    const maskUtils = new MaskUtils({ mask, maskPlaceholder });

    const isMasked = !!mask;
    const isEditable = !restProps.disabled && !restProps.readOnly;
    const isControlled = restProps.value !== null && restProps.value !== undefined;
    const previousIsMasked = usePrevious(isMasked);
    const initialValue = toString((isControlled ? restProps.value : restProps.defaultValue) || '');

    const { inputRef, getInputState, setInputState, getLastInputState } = useInputState(
        initialValue,
        isMasked
    );
    const getInputElement = useInputElement(inputRef);

    function onChange(event: any) {
        const currentState = getInputState();
        const previousState = getLastInputState();
        let newInputState = maskUtils.processChange(currentState, previousState);

        if (beforeMaskedStateChange) {
            newInputState = beforeMaskedStateChange({
                currentState,
                previousState,
                nextState: newInputState
            });
        }

        setInputState(newInputState);

        if (restProps.onChange) {
            restProps.onChange(event);
        }
    }

    function onFocus(event: any) {
        // If autoFocus property is set, focus event fires before the ref handler gets called
        inputRef.current = event.target;

        const currentValue = getInputState().value;

        if (isMasked && !maskUtils.isValueFilled(currentValue)) {
            let newValue = maskUtils.formatValue(currentValue);
            const newSelection = maskUtils.getDefaultSelectionForValue(newValue);
            let newInputState = {
                value: newValue,
                selection: newSelection
            };

            if (beforeMaskedStateChange) {
                newInputState = beforeMaskedStateChange({
                    currentState: getInputState(),
                    nextState: newInputState
                });
                newValue = newInputState.value;
            }

            setInputState(newInputState);

            if (newValue !== currentValue && restProps.onChange) {
                restProps.onChange(event);
            }

            // Chrome resets selection after focus event,
            // so we want to restore it later
            defer(() => {
                setInputState(getLastInputState());
            });
        }

        if (restProps.onFocus) {
            restProps.onFocus(event);
        }
    }

    function onBlur(event: any) {
        const currentValue = getInputState().value;
        const lastValue = getLastInputState().value;

        if (isMasked && !alwaysShowMask && maskUtils.isValueEmpty(lastValue)) {
            let newValue = '';
            let newInputState = {
                value: newValue,
                selection: { start: null, end: null }
            };

            if (beforeMaskedStateChange) {
                newInputState = beforeMaskedStateChange({
                    currentState: getInputState(),
                    nextState: newInputState
                });
                newValue = newInputState.value;
            }

            setInputState(newInputState);

            if (newValue !== currentValue && restProps.onChange) {
                restProps.onChange(event);
            }
        }

        if (restProps.onBlur) {
            restProps.onBlur(event);
        }
    }

    // Tiny unintentional mouse movements can break cursor
    // position on focus, so we have to restore it in that case
    //
    // https://github.com/sanniassin/react-input-mask/issues/108
    function onMouseDown(event: any) {
        const input = getInputElement();
        if (!input) {
            return;
        }
        const { value } = getInputState();
        const inputDocument = getElementDocument(input);

        if (!isInputFocused(input) && !maskUtils.isValueFilled(value)) {
            const mouseDownX = event.clientX;
            const mouseDownY = event.clientY;
            const mouseDownTime = new Date().getTime();

            const mouseUpHandler = (mouseUpEvent: any) => {
                inputDocument.removeEventListener('mouseup', mouseUpHandler);

                if (!isInputFocused(input)) {
                    return;
                }

                const deltaX = Math.abs(mouseUpEvent.clientX - mouseDownX);
                const deltaY = Math.abs(mouseUpEvent.clientY - mouseDownY);
                const axisDelta = Math.max(deltaX, deltaY);
                const timeDelta = new Date().getTime() - mouseDownTime;

                if ((axisDelta <= 10 && timeDelta <= 200) || (axisDelta <= 5 && timeDelta <= 300)) {
                    const lastState = getLastInputState();
                    const newSelection = maskUtils.getDefaultSelectionForValue(lastState.value);
                    const newState = {
                        ...lastState,
                        selection: newSelection
                    };
                    setInputState(newState);
                }
            };

            inputDocument.addEventListener('mouseup', mouseUpHandler);
        }

        if (restProps.onMouseDown) {
            restProps.onMouseDown(event);
        }
    }

    // For controlled inputs we want to provide properly formatted
    // value prop
    if (isMasked && isControlled) {
        const input = getInputElement();
        const isFocused = input && isInputFocused(input);
        let newValue =
            isFocused || alwaysShowMask || restProps.value
                ? maskUtils.formatValue(restProps.value)
                : restProps.value;

        if (beforeMaskedStateChange) {
            newValue = beforeMaskedStateChange({
                nextState: { value: newValue, selection: { start: null, end: null } }
            }).value;
        }

        setInputState({
            ...getLastInputState(),
            value: newValue
        });
    }

    const lastState = getLastInputState();
    const lastSelection = lastState.selection;
    const lastValue = lastState.value;

    useLayoutEffect(() => {
        if (!isMasked) {
            return;
        }

        const input = getInputElement();
        if (!input) {
            return;
        }
        const isFocused = isInputFocused(input);
        const previousSelection = lastSelection;
        const currentState = getInputState();
        let newInputState = { ...currentState };

        // Update value for uncontrolled inputs to make sure
        // it's always in sync with mask props
        if (!isControlled) {
            const currentValue = currentState.value;
            const formattedValue = maskUtils.formatValue(currentValue);
            const isValueEmpty = maskUtils.isValueEmpty(formattedValue);
            const shouldFormatValue = !isValueEmpty || isFocused || alwaysShowMask;
            if (shouldFormatValue) {
                newInputState.value = formattedValue;
            } else if (isValueEmpty && !isFocused) {
                newInputState.value = '';
            }
        }

        if (isFocused && !previousIsMasked) {
            // Adjust selection if input got masked while being focused
            //@ts-ignore
            newInputState.selection = maskUtils.getDefaultSelectionForValue(newInputState.value);
        } else if (isControlled && isFocused && previousSelection) {
            // Restore cursor position if value has changed outside change event
            if (previousSelection.start !== null && previousSelection.end !== null) {
                //@ts-ignore
                newInputState.selection = previousSelection;
            }
        }

        if (beforeMaskedStateChange) {
            newInputState = beforeMaskedStateChange({
                currentState,
                nextState: newInputState
            });
        }

        setInputState(newInputState);
    });

    const refCallback = (node: any) => {
        inputRef.current = node;

        // if a ref callback is passed to InputMask
        if (isFunction(forwardedRef)) {
            //@ts-ignore
            forwardedRef(node);
        } else if (forwardedRef !== null && typeof forwardedRef === 'object') {
            forwardedRef.current = node;
        }
    };

    const inputProps = {
        ...restProps,
        onFocus,
        onBlur,
        onChange: isMasked && isEditable ? onChange : restProps.onChange,
        onMouseDown: isMasked && isEditable ? onMouseDown : restProps.onMouseDown,
        value: isMasked && isControlled ? lastValue : restProps.value
    };

    if (children) {
        validateChildren(props, children);

        // {@link https://stackoverflow.com/q/63149840/327074}
        const onlyChild = React.Children.only(children);
        return React.cloneElement(onlyChild, { ...inputProps, ref: refCallback });
    }

    return <input ref={refCallback} {...inputProps} />;
});

InputMask.displayName = 'InputMask';

export default InputMask;
