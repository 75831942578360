import React, { FC, SVGAttributes } from 'react';

const Edit: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.06 4.07865L20.41 5.42865C21.2 6.20865 21.2 7.47865 20.41 8.25865L7.18 21.4887H3V17.3087L13.4 6.89865L16.23 4.07865C17.01 3.29865 18.28 3.29865 19.06 4.07865ZM5 19.4887L6.41 19.5487L16.23 9.71865L14.82 8.30865L5 18.1287V19.4887Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Edit;
